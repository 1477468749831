export const styles = {
    root: {
        backgroundColor: '#fff',
    },
    rootInner: {
        maxWidth: 1024,
        width: '100%',
        margin: '30px auto',
        color: '#6B6B6B',
        fontFamily: '"Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif',
        fontSize: 13,
    },
    block: {
        marginBottom: 30,
    },
    title: {
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 30,
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 30,
    },
    line: {
        borderColor: '#DFDFDF',
    },
};
