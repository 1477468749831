import * as emailjs from 'emailjs-com';
import { ErrorMessage, Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { bool, lazy, object, string } from 'yup';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { styles } from './styles';
import { toast } from 'react-toastify';

const useStyles = createUseStyles(styles, {
    name: 'ContactUsForm',
});

const EMAIL_USER_ID = 'user_HCd4PniUSe59qzeVKQi4h';
const EMAIL_TEMPLATE_ID = 'template_i6sc358';

const validationSchema = ({ langProps }) => {
    return lazy(() => {
        return object().shape({
            lastName: string().required(langProps.lastNameError),
            company: string().required(langProps.companyError),
            town: string(),
            phone: string().required(langProps.phoneError),
            email: string().email(langProps.emailValidateError).required(langProps.emailError),
            request: string().required(langProps.requestError),
            isAgree: bool(langProps.agreeError)
                .test('', langProps.agreeError, (isAgree) => !!isAgree)
                .required(langProps.agreeError),
        });
    });
};

const ContactUsForm = ({ isSubmitting, langProps, setErrors, lang }) => {
    const classes = useStyles();

    useEffect(() => {
        setErrors({});
    }, [lang]);

    useEffect(() => {
        emailjs.init(EMAIL_USER_ID);
    }, []);

    return (
        <Form className={classes.root}>
            <label className={classes.label}>
                <div className={classes.text}>{langProps.lastName + '*'}</div>
                <Field className={classes.field} name="lastName" component="input" />
                <ErrorMessage name="lastName" component="div" className={classes.error} />
            </label>
            <label className={classes.label}>
                <div className={classes.text}>{langProps.company + '*'}</div>
                <Field className={classes.field} name="company" component="input" />
                <ErrorMessage name="company" component="div" className={classes.error} />
            </label>
            <label className={classes.label}>
                <div className={classes.text}>{langProps.town}</div>
                <Field className={classes.field} name="town" component="input" />
            </label>
            <label className={classes.label}>
                <div className={classes.text}>{langProps.phone + '*'}</div>
                <Field className={classes.field} name="phone" component="input" />
                <ErrorMessage name="phone" component="div" className={classes.error} />
            </label>
            <label className={classes.label}>
                <div className={classes.text}>Email*</div>
                <Field className={classes.field} name="email" component="input" type="email" />
                <ErrorMessage name="email" component="div" className={classes.error} />
            </label>
            <label className={classes.label}>
                <div className={classes.text}>{langProps.request + '*'}</div>
                <Field className={classes.field} name="request" component="textarea" rows={6} />
                <ErrorMessage name="request" component="div" className={classes.error} />
            </label>
            <div className={classes.labelCheckbox}>
                <Field className={classes.checkbox} name="isAgree" component="input" type="checkbox" />
                <Link to={`full-license/${lang}`} className={classes.link} target="_blank">
                    {langProps.agree}
                </Link>
                <ErrorMessage name="isAgree" component="div" className={classes.error} />
            </div>
            <button className={classes.button} type="submit" disabled={isSubmitting}>
                {langProps.button}
            </button>
        </Form>
    );
};

ContactUsForm.propTypes = {
    isSubmitting: propTypes.bool,
    resetForm: propTypes.func.isRequired,
    langProps: propTypes.object.isRequired,
    setErrors: propTypes.func.isRequired,
    lang: propTypes.string.isRequired,
};

const WrappedContactUsForm = withFormik({
    mapPropsToValues: () => ({
        lastName: '',
        company: '',
        town: '',
        phone: '',
        email: '',
        request: '',
        isAgree: false,
    }),
    handleSubmit: (
        values,
        {
            props: {
                langProps: { successMessage, errorMessage },
            },
            resetForm,
        },
    ) => {
        emailjs
            .send('default_service', EMAIL_TEMPLATE_ID, {
                name: values.lastName,
                company: values.company,
                town: values.town,
                phone: values.phone,
                email: values.email,
                request: values.request,
            })
            .then(() => {
                toast.success(successMessage);
                resetForm();
            })
            .catch(() => {
                toast.error(errorMessage);
            });
    },
    validationSchema,
})(ContactUsForm);

export { WrappedContactUsForm as ContactUsForm };
