import './index.scss';
import React, { Component } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Routes } from './Routes';
import { combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reportWebVitals from './reportWebVitals';

export class Root extends Component {
    componentDidMount() {
        ReactGA.initialize('G-GHL99EJRTC');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <BrowserRouter>
                <div className="root">
                    <Routes />
                </div>
            </BrowserRouter>
        );
    }
}

const configureStore = () => {
    const reducers = combineReducers({});

    const store = createStore(reducers, composeWithDevTools(applyMiddleware()));

    return store;
};

ReactDOM.render(
    <Provider store={configureStore()}>
        <Root />
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
