import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { HEADER_LANGUAGES } from '../../constants/headerLanguages';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { styles } from './styles';
import { withLang } from 'react-multi-language';
import { withRouter } from 'react-router';

const getImageForLanguage = (lang) => {
    const src = `${process.env.PUBLIC_URL}/images/${lang}.png`;
    return <img alt="language" src={src} />;
};

const useStyles = createUseStyles(styles, {
    name: 'Header',
});

export const Header = withRouter(
    withLang(HEADER_LANGUAGES)(({ langProps, changeLang, lang, match: { path } }) => {
        const classes = useStyles();
        const [isOpened, setIsOpened] = useState(false);

        const handleOpeningNavbar = useCallback(() => {
            setIsOpened((prevState) => !prevState);
        }, []);

        useEffect(() => {
            window.addEventListener('resize', (event) => {
                if (event.target?.innerWidth && event.target.innerWidth > 992) {
                    setIsOpened(false);
                }
            });
        }, []);

        return (
            <div className={classes.root}>
                <Link to="home" className={classNames(classes.link, classes.linkLogo)}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/main_logo.png`}
                        alt="NOLANTO logo"
                        className={classes.logo}
                        onClick={() => isOpened && handleOpeningNavbar()}
                    />
                </Link>
                <div className="header__icons">
                    {!isOpened ? (
                        <img
                            src={`${process.env.PUBLIC_URL}/images/menu.svg`}
                            alt="menu-icon"
                            className={classes.icon}
                            onClick={handleOpeningNavbar}
                        />
                    ) : (
                        <img
                            src={`${process.env.PUBLIC_URL}/images/close.svg`}
                            alt="close-icon"
                            className={classNames(classes.icon, classes.iconClose)}
                            onClick={handleOpeningNavbar}
                        />
                    )}
                </div>
                <div className={classNames(classes.menu, isOpened && classes.openedMenu)}>
                    <div className={classes.list}>
                        {langProps.titles?.map(({ title, route, subTitles }) => (
                            <div
                                className={classNames(
                                    classes.item,
                                    (subTitles?.length
                                        ? subTitles.some((element) => `/${element.route}` === path)
                                        : path === `/${route}`) && classes.active,
                                )}
                                key={route}
                            >
                                {!!subTitles?.length && (
                                    <div className={classes.link}>
                                        <span className={classes.textMenu}>{title}</span>
                                        <div className={classes.submenu}>
                                            {subTitles?.map(({ title: subTitle, route: subRoute }) => (
                                                <Fragment key={subRoute}>
                                                    <Link
                                                        to={subRoute}
                                                        className={classNames(classes.link, classes.linkBlock)}
                                                        onClick={() => isOpened && handleOpeningNavbar()}
                                                    >
                                                        <span className={classes.textMenu}>{subTitle}</span>
                                                    </Link>
                                                </Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {!subTitles?.length && (
                                    <Fragment>
                                        <Link
                                            to={route}
                                            className={classes.link}
                                            onClick={() => isOpened && handleOpeningNavbar()}
                                        >
                                            <span className={classes.textMenu}>{title}</span>
                                        </Link>
                                    </Fragment>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={classes.langs}>
                        <Select
                            options={[
                                { value: 'ru', label: getImageForLanguage('ru') },
                                { value: 'ua', label: getImageForLanguage('ua') },
                                { value: 'en', label: getImageForLanguage('en') },
                            ]}
                            onChange={({ value }) => changeLang(value)}
                            className={classes.select}
                            defaultValue={{ value: lang, label: getImageForLanguage(lang) }}
                            value={{ value: lang, label: getImageForLanguage(lang) }}
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>
        );
    }),
);

Header.propTypes = {
    changeLang: propTypes.func,
    langProps: propTypes.object,
    lang: propTypes.string,
};
