export const seoDataLanguages = {
    'ru': {
        title: 'Контакты',
        description: 'Контакты компании NOLANTO LTD',
        keywords:
            'компания NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехника, электроника, услуги, консалтинговые услуги, юридические услуги, финансовые услуги, пиломатериалы, логистические услуги, логистика, контакты',
    },
    'en': {
        title: 'Contacts',
        description: 'Contact NOLANTO LTD',
        keywords:
            'company NOLANTO, NOLANTO LTD, Nolanto, slag, plumbing, electronics, services, consulting services, legal services, financial services, lumber, logistics services, logistics, contacts',
    },
    'ua': {
        title: 'Контакти',
        description: 'Контакти компанії NOLANTO LTD',
        keywords:
            'компанія NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехніка, електроніка, послуги, консалтингові послуги, юридичні послуги, фінансові послуги, пиломатеріали, логістичні послуги, логістика, контакти',
    },
};
