export const styles = {
    root: {
        padding: '0 100px',
        width: 'calc(100vw - 200px)',
        background: '#425c5a',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: 80,
        zIndex: 20,

        '@media (max-width: 1200px)': {
            padding: '0 10px',
            width: 'calc(100vw - 20px)',
        },

        '@media (max-width: 767px)': {
            padding: '0 50px 0 0',
            width: 'calc(100vw - 50px)',
        },

        '@media (max-width: 992px)': {
            position: 'fixed',
        },
    },
    textMenu: {
        height: 20,
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
        textAlign: 'center',
        maxWidth: '100%',
        maxHeight: 100,
        padding: '5px 10px',
        fontWeight: 700,
        margin: '0 10px',
        position: 'relative',
        cursor: 'pointer',
        height: 'calc(100% - 10px)',
        display: 'flex',
        alignItems: 'center',

        '@media (max-width: 992px)': {
            display: 'block',
            maxHeight: 'initial',
            height: 'auto',
        },

        '&:hover': {
            '& > $textMenu': {
                borderBottom: '1px solid rgb(50, 205, 50)',
                height: 19,
            },

            '& > $submenu': {
                display: 'block',
            },
        },
    },
    linkBlock: {
        display: 'block',
    },
    logo: {
        maxWidth: 111,
        maxHeight: '100%',
    },
    linkLogo: {
        '@media (max-width: 767px)': {
            padding: '5px 10px 5px 0',
            margin: '0 10px 0 0',
        },
    },
    icon: {
        width: 24,
        height: 24,
        display: 'none',

        '@media (max-width: 992px)': {
            display: 'block',
        },
    },
    iconClose: {},
    menu: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        margin: 20,
        minHeight: 80,
        height: 80,

        '@media (max-width: 992px)': {
            display: 'none',
            minHeight: 'auto',
            height: 'auto',
        },
    },
    list: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        minHeight: 80,
        height: 80,

        '@media (max-width: 992px)': {
            minHeight: 'auto',
            height: 'auto',
        },
    },
    openedMenu: {
        display: 'block',
        position: 'fixed',
        top: 80,
        right: 0,
        width: 'auto',
        minWidth: 200,
        minHeight: '100%',
        margin: 0,
        background: '#a2bfbd',

        '& > $list': {
            display: 'block',
        },
    },
    item: {
        position: 'relative',
        height: '100%',

        '@media (max-width: 992px)': {
            margin: '20px 10px',
        },
    },
    select: {
        width: 80,
        margin: '0 auto',
        outline: 'none',
    },
    line: {
        display: 'none',
        position: 'absolute',
        width: 'calc(100% - 20px)',
        height: 2,
        margin: '0 10px',
        background: '#fff',
        bottom: 5,
    },
    submenu: {
        background: '#425c5a',
        padding: '0 0 15px',
        display: 'none',
        position: 'absolute',
        minWidth: 'fit-content',
        top: 80,
        left: 0,
        width: '100%',
        zIndex: 10,

        '@media (max-width: 992px)': {
            top: 0,
            padding: 0,
            position: 'relative',
            left: 0,
            minWidth: 'auto',
            width: 'auto',
            marginTop: 10,
        },

        '& $link:hover + $line': {
            display: 'none',
        },
    },
    active: {
        '& > $link > $textMenu': {
            borderBottom: '1px solid rgb(50, 205, 50)',
            height: 19,
        },
    },
};
