import { MetaTags } from 'react-meta-tags';
import React from 'react';
import propTypes from 'prop-types';

export const SeoTags = ({ seo }) => {
    return (
        <MetaTags>
            {seo.title && <title>{seo.title}</title>}
            {seo.description && <meta name="description" content={seo.description} />}
            {seo.keywords && <meta property="keywords" content={seo.keywords} />}
            {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        </MetaTags>
    );
};

SeoTags.propTypes = {
    seo: propTypes.object,
};
