import React from 'react';

import { createUseStyles } from 'react-jss';

import { styles } from './styles';

const useStyles = createUseStyles(styles, {
    name: 'Footer',
});

export const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.text}>&copy;2020 NOLANTO. All Rights Reserved. Privacy Policy</div>
        </div>
    );
};
