import 'react-toastify/dist/ReactToastify.css';
import React, { Fragment, useEffect, useState } from 'react';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { MultiLang } from 'react-multi-language';
import { ToastContainer } from 'react-toastify';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { renderablePropTypes } from '../../utils/renderablePropTypes';

import { styles } from './styles';

const useStyles = createUseStyles(styles, {
    name: 'MainLayout',
});

export const MainLayout = ({ children, withHeader, withLang, withFooter }) => {
    const classes = useStyles();
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru');

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const changeLang = (newLang) => setLang(newLang);

    return (
        <Fragment>
            {withHeader && <Header changeLang={changeLang} />}
            <div className={classes.root}>
                <ToastContainer autoClose={5000} />
                {children}
            </div>
            {withFooter && <Footer />}
            {withLang && <MultiLang lang={lang} />}
        </Fragment>
    );
};

MainLayout.propTypes = {
    withHeader: propTypes.bool,
    children: renderablePropTypes,
    withLang: propTypes.bool,
    withFooter: propTypes.bool,
};

MainLayout.defaultProps = {
    withHeader: true,
    withLang: true,
    withFooter: true,
};
