export const styles = {
    root: {
        margin: '0 50px',
        textAlign: 'center',
        width: 'calc(100% - 100px)',

        '@media (max-width: 992px)': {
            margin: '0 10px',
            width: 'calc(100% - 20px)',
        },
    },
    label: {
        display: 'flex',
        color: '#425c5a',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 15,
        flexFlow: 'row wrap',
        textAlign: 'left',
    },
    field: {
        minWidth: 250,
        width: '100%',
        border: '1px solid #425c5a',
        borderRadius: 15,
        padding: '10px 20px',
        outline: 'none',
        fontSize: 16,
        lineHeight: '24px',
    },
    text: {
        width: '100%',
        marginBottom: 7,
    },
    error: {
        fontSize: 12,
        color: 'red',
        width: '100%',
    },
    button: {
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: 16,
        background: '#019e01',
        color: '#fff',
        fontWeight: 500,
        transition: '.3s all',
        outline: 'none',

        '&:hover': {
            background: '#425c5a',
        },
    },
    labelCheckbox: {
        color: '#425c5a',
        fontSize: 14,
        alignItems: 'center',
        fontWeight: 500,
        marginBottom: 15,
        textAlign: 'left',
        display: 'flex',
        flexFlow: 'row wrap',
    },
    link: {
        color: '#000',
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
};
