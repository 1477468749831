import { Redirect, Route, Switch } from 'react-router';
import { ContactsPage } from './pages/ContactsPage';
import { ElectronicsPage } from './pages/ElectronicsPage';
import { FinancialConsultingPage } from './pages/FinancialConsultingPage';
import { FullPrivatePolicyPage } from './pages/FullPrivatePolicyPage/FullPrivatePolicyPage';
import { HomePage } from './pages/HomePage';
import { LegalConsultingPage } from './pages/LegalConsultingPage';
import { LogisticConsultingPage } from './pages/LogisticConsultingPage';
import { LumberPage } from './pages/LumberPage';
import { MainLayout } from './components/MainLayout';
import { PlumbingPage } from './pages/PlumbingPage';
import React from 'react';
import { SlagPage } from './pages/SlagPage';

export const Routes = () => (
    <Switch>
        <Route
            path="/home"
            exact
            render={() => (
                <MainLayout>
                    <HomePage />
                </MainLayout>
            )}
        />
        <Route
            path="/contacts"
            exact
            render={() => (
                <MainLayout>
                    <ContactsPage />
                </MainLayout>
            )}
        />
        <Route
            path="/full-license/:lang"
            exact
            render={() => (
                <MainLayout withHeader={false} withFooter={false} withLang={false}>
                    <FullPrivatePolicyPage />
                </MainLayout>
            )}
        />
        <Route
            path="/lumber"
            exact
            render={() => (
                <MainLayout>
                    <LumberPage />
                </MainLayout>
            )}
        />
        <Route
            path="/slag"
            exact
            render={() => (
                <MainLayout>
                    <SlagPage />
                </MainLayout>
            )}
        />
        <Route
            path="/plumbing"
            exact
            render={() => (
                <MainLayout>
                    <PlumbingPage />
                </MainLayout>
            )}
        />
        <Route
            path="/electronics"
            exact
            render={() => (
                <MainLayout>
                    <ElectronicsPage />
                </MainLayout>
            )}
        />
        <Route
            path="/logistic-consulting"
            exact
            render={() => (
                <MainLayout>
                    <LogisticConsultingPage />
                </MainLayout>
            )}
        />
        <Route
            path="/law-consulting"
            exact
            render={() => (
                <MainLayout>
                    <LegalConsultingPage />
                </MainLayout>
            )}
        />
        <Route
            path="/financial-consulting"
            exact
            render={() => (
                <MainLayout>
                    <FinancialConsultingPage />
                </MainLayout>
            )}
        />
        <Redirect to="/home" />
    </Switch>
);
