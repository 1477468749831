export const seoDataLanguages = {
    'ru': {
        title: 'Юридический консалтинг',
        description: 'Юридический консалтинг',
        keywords:
            'компания NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехника, электроника, услуги, консалтинговые услуги, юридические услуги, финансовые услуги, пиломатериалы, логистические услуги, логистика',
    },
    'en': {
        title: 'Legal consulting',
        description: 'Legal consulting',
        keywords:
            'company NOLANTO, NOLANTO LTD, Nolanto, slag, plumbing, electronics, services, consulting services, legal services, financial services, lumber, logistics services, logistics',
    },
    'ua': {
        title: 'Юридичний консалтинг',
        description: 'Юридичний консалтинг',
        keywords:
            'компанія NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехніка, електроніка, послуги, консалтингові послуги, юридичні послуги, фінансові послуги, пиломатеріали, логістичні послуги, логістика',
    },
};
