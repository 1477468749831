import React, { Fragment, useState } from 'react';
import { CONTACTS_LANGUAGES } from '../../constants/contactsLanguages';
import { ContactUsForm } from './ContactUsForm';
import { Loader } from '../../components/Loader';
import { SeoTags } from '../../components/SeoTags';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'ContactsPage',
});

export const ContactsPage = withLang(CONTACTS_LANGUAGES)(({ langProps, lang }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <div className={classes.title}>{langProps.title}</div>
                    <div className={classes.inner}>
                        <div className={classes.form}>
                            <ContactUsForm langProps={langProps} lang={lang} />
                        </div>
                        <div className={classes.contacts}>
                            <div className={classes.contact}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/home-address.svg`}
                                    alt="contact icon"
                                    className={classes.icon}
                                    style={{ marginTop: -35 }}
                                />
                                <div className={classes.item}>
                                    <div className={classes.text}>
                                        NOLANTO LTD
                                        <br />
                                        Spyrou Kyprianou 61
                                        <br />
                                        4003 Limassol
                                        <br />
                                        Cyprus
                                        <br />
                                        <br />
                                        Reg. No: HE381093
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contact}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/phone-call.svg`}
                                    alt="contact icon"
                                    className={classes.icon}
                                />
                                <div className={classes.item}>
                                    <a href={'tel:+37281703833'} className={classes.link}>
                                        +3728-170-3833
                                    </a>
                                </div>
                            </div>
                            <div className={classes.contact}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/email.svg`}
                                    alt="contact icon"
                                    className={classes.icon}
                                />
                                <div className={classes.item}>
                                    <a href={'mailto:nolanto.company@gmail.com'} className={classes.link}>
                                        nolanto.company@gmail.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
});

ContactsPage.propTypes = {
    langProps: propTypes.object,
};
