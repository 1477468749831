import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createElementsFromText } from 'html-text-to-react';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'LogisticConsultingPage',
});

export const LogisticConsultingPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'NOLANTO   оказывает консультационную поддержку компаний по вопросам транспортной логистики. '
                                            : lang === 'ua'
                                            ? 'NOLANTO надає консультаційну підтримку компаній з питань транспортної логістики.'
                                            : 'NOLANTO provides consulting support to companies on transport logistics issues.'}
                                    </div>
                                    <div className={classes.text}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_5_1.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                        />
                                        <div className={classes.title}>
                                            {lang === 'ru'
                                                ? 'Наши консультационные услуги по транспортной логистике включают в себя:'
                                                : lang === 'ua'
                                                ? 'Наші консультаційні послуги з транспортної логістики включають в себе:'
                                                : 'Our transport logistics consulting services include:'}
                                        </div>
                                        <ul className={classes.list}>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Построение маршрута;'
                                                    : lang === 'ua'
                                                    ? 'Побудова маршруту;'
                                                    : 'Building a route;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Определение вида и/или комбинации транспорта;'
                                                    : lang === 'ua'
                                                    ? 'Визначення виду і / або комбінації транспорту;'
                                                    : 'Determination of the type and / or combination of transport;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Подбор перевозчиков;'
                                                    : lang === 'ua'
                                                    ? 'Підбір перевізників;'
                                                    : 'Selection of carriers;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Организация корректного оформления и движения документов.'
                                                    : lang === 'ua'
                                                    ? 'Організація коректного оформлення і руху документів.'
                                                    : 'Organization of the correct execution and movement of documents.'}
                                            </li>
                                        </ul>
                                        <div className={classes.title}>
                                            {lang === 'ru'
                                                ? 'Применение на практике наших решений позволяет нашим клиентам значительно повышать эффективность своего бизнеса за счет:'
                                                : lang === 'ua'
                                                ? 'Застосування на практиці наших рішень дозволяє нашим клієнтам значно підвищувати ефективність свого бізнесу за рахунок:'
                                                : 'Practical application of our solutions allows our clients to significantly increase the efficiency of their business due to:'}
                                        </div>
                                        <ul className={classes.list}>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Сокращения транспортно-логистических издержек;'
                                                    : lang === 'ua'
                                                    ? 'Скорочення транспортно-логістичних витрат;'
                                                    : 'Reducing transport and logistics costs;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Снижения рисков;'
                                                    : lang === 'ua'
                                                    ? 'Зниження ризиків;'
                                                    : 'Reducing risks;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Своевременности доставки товаров;'
                                                    : lang === 'ua'
                                                    ? 'Своєчасності доставки товарів;'
                                                    : 'Timeliness of delivery of goods;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Оптимизации документооборота;'
                                                    : lang === 'ua'
                                                    ? 'Оптимізації документообігу;'
                                                    : 'Optimization of document flow;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Повышения точности планирования финансовых и товарных потоков.'
                                                    : lang === 'ua'
                                                    ? 'Підвищення точності планування фінансових і товарних потоків.'
                                                    : 'Improving the accuracy of planning financial and commodity flows.'}
                                            </li>
                                        </ul>
                                        <div className={classes.text}>
                                            {createElementsFromText(
                                                lang === 'ru'
                                                    ? '<strong>Комплексный логистический подход</strong> позволяет нам достичь значительной <strong>рентабельности за счет использования</strong> комбинированных перевозок. Возможность предоставления широкого спектра логистических услуг делает <strong>NOLANTO</strong> вашим идеальным партнером не только по качеству услуг, но и по полноте услуг, предоставляя <strong>универсальный и высоконадежный выбор.</strong>'
                                                    : lang === 'ua'
                                                    ? '<strong>Комплексний логістичний підхід</strong> дозволяє нам досягти значної <strong>рентабельності за рахунок використання</strong> комбінованих перевезень. Можливість надання широкого спектра логістичних послуг робить <strong>NOLANTO</strong> вашим ідеальним партнером не тільки за якістю послуг, але і по повноті послуг, надаючи <strong>універсальний та високо вибір.</strong>'
                                                    : '<strong>An integrated logistics approach</strong> allows us to achieve significant <strong>profitability through the use of</strong> combined transport. The ability to provide a wide range of logistics services makes <strong>NOLANTO</strong> your ideal partner not only for the quality of services, but also for the completeness of services, providing <strong>a versatile and highly reliable choice.</strong>',
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'NOLANTO   оказывает консультационные услуги по ведению внешнеэкономической деятельности, как компаниям, которые делают первые шаги в этой области, так и компаниям, которые хотят оптимизировать и развить свою деятельность.'
                                            : lang === 'ua'
                                            ? 'NOLANTO надає консультаційні послуги з ведення зовнішньоекономічної діяльності, як компаніям, які роблять перші кроки в цій області, так і компаніям, які хочуть оптимізувати і розвинути свою діяльність.'
                                            : 'NOLANTO provides consulting services in the conduct of foreign economic activity, both to companies that are taking their first steps in this area and to companies that want to optimize and develop their activities.'}
                                    </div>
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Мы консультируем по следующим темам:'
                                            : lang === 'ua'
                                            ? 'Ми консультуємо за наступними темами:'
                                            : 'We advise on the following topics:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Оформление внешнеторгового контракта с поставщиками или покупателями товаров;'
                                                : lang === 'ua'
                                                ? 'Оформлення зовнішньоторговельного контракту з постачальниками або покупцями товарів;'
                                                : 'Registration of a foreign trade contract with suppliers or buyers of goods;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Выбор оптимального маршрута доставки груза;'
                                                : lang === 'ua'
                                                ? 'Вибір оптимального маршруту доставки вантажу;'
                                                : 'Selection of the optimal route of cargo delivery;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Оформление необходимых сертификатов, лицензий, разрешений;'
                                                : lang === 'ua'
                                                ? 'Оформлення необхідних сертифікатів, ліцензій, дозволів;'
                                                : 'Registration of necessary certificates, licenses, permissions;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Подготовка и оформление полного комплекта документов для таможенного оформления товаров;'
                                                : lang === 'ua'
                                                ? 'Підготовка та оформлення повного комплекту документів для митного оформлення товарів;'
                                                : 'Preparation and execution of a complete set of documents for customs clearance of goods;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Регистрация фирмы участника ВЭД в таможенных органах;'
                                                : lang === 'ua'
                                                ? 'Реєстрація фірми учасника ЗЕД в митних органах;'
                                                : 'Registration of a company participating in foreign economic activity in the customs authorities;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Проведение таможенного оформления импортных и экспортных грузов;'
                                                : lang === 'ua'
                                                ? 'Проведення митного оформлення імпортних та експортних вантажів;'
                                                : 'Carrying out customs clearance of import and export cargo;'}
                                        </li>
                                        <li className={classes.item}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/product_img_5_2.png`}
                                                alt="image"
                                                className={classNames(classes.img, classes.right)}
                                            />
                                            {lang === 'ru'
                                                ? 'Содействие и консультации в решении спорных ситуаций в таможенных органах;'
                                                : lang === 'ua'
                                                ? 'Сприяння і консультації у вирішенні спірних ситуацій в митних органах;'
                                                : 'Assistance and advice in resolving disputes in the customs authorities;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Консультации и проведение специфических экспертиз, требуемых для таможенного оформления товаров.'
                                                : lang === 'ua'
                                                ? 'Консультації та проведення специфічних експертиз, необхідних для митного оформлення товарів.'
                                                : 'Consulting and carrying out specific examinations required for customs clearance of goods.'}
                                        </li>
                                    </ul>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Профессиональная консультационная поддержка со стороны NOLANTO    позволит Вам максимально эффективно осуществлять внешнеэкономическую деятельность, снижая риски потери временных и материальных ресурсов.'
                                            : lang === 'ua'
                                            ? 'Професійна консультаційна підтримка з боку NOLANTO дозволить Вам максимально ефективно здійснювати зовнішньоекономічну діяльність, знижуючи ризики втрати тимчасових і матеріальних ресурсів.'
                                            : 'Professional consulting support from NOLANTO will allow you to carry out foreign economic activities as efficiently as possible, reducing the risk of losing time and material resources.'}
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

LogisticConsultingPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
