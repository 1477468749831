import React, { Fragment, useState } from 'react';
import { HEADER_LANGUAGES } from '../../constants/headerLanguages';
import { HOME_LANGUAGES } from '../../constants/homeLanguages';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createElementsFromText } from 'html-text-to-react';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles.js';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'HomePage',
});

export const HomePage = withLang({
    en: { ...HEADER_LANGUAGES.en, ...HOME_LANGUAGES.en },
    ru: { ...HEADER_LANGUAGES.ru, ...HOME_LANGUAGES.ru },
    ua: { ...HEADER_LANGUAGES.ua, ...HOME_LANGUAGES.ua },
})(({ langProps, lang }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <div className={classes.home}>
                        <div className={classes.textItalic}>{langProps.homeSentense1}</div>
                        <div className={classes.textItalic}>{langProps.homeSentense2}</div>
                        <div className={classes.textItalic} style={{ marginBottom: 15 }}>
                            {langProps.homeSentense3}
                        </div>
                        <div className={classes.text}>{langProps.homeText1}</div>
                        <br />
                        <div className={classes.text}>{createElementsFromText(langProps.homeText2)}</div>
                        <br />
                        <div className={classes.text}>{createElementsFromText(langProps.homeText3)}</div>
                        <br />
                        <div className={classes.boldText}>{langProps.homeEnd}</div>
                    </div>
                    <div className={classes.products}>
                        {langProps.titles?.map(({ title, route, img, isProduct, subTitles }, index) => {
                            if (isProduct && !subTitles?.length) {
                                return (
                                    <Link to={route} key={index} className={classes.product}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${process.env.PUBLIC_URL}/images/${img})`,
                                            }}
                                            className={classes.productImg}
                                        />
                                        <div className={classes.productText}>{title}</div>
                                    </Link>
                                );
                            }
                            if (isProduct && !!subTitles?.length) {
                                return (
                                    <div
                                        key={index}
                                        className={classNames(classes.product, classes.productNoTransform)}
                                    >
                                        <div
                                            style={{
                                                backgroundImage: `url(${process.env.PUBLIC_URL}/images/${img})`,
                                            }}
                                            className={classes.productImg}
                                        />
                                        <div className={classes.submenuI}>
                                            <div className={classes.submenu}>
                                                {subTitles.map(
                                                    ({ route: subRoute, title: subTitle, img: subImg }, index) => (
                                                        <Link to={subRoute} key={index} className={classes.product}>
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${subImg})`,
                                                                }}
                                                                className={classes.productImg}
                                                            />
                                                            <div className={classes.productText}>{subTitle}</div>
                                                        </Link>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                        <div className={classes.productText}>{title}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </Fragment>
            )}
        </div>
    );
});

HomePage.propTypes = {
    langProps: propTypes.object,
    lang: propTypes.string,
};
