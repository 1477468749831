export const styles = {
    root: {
        minHeight: 'calc(100vh - 140px)',
        width: '100%',
        background: '#F8F8F8',

        '@media (max-width: 992px)': {
            paddingTop: 80,
        },
    },
};
