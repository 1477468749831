export const styles = {
    root: {
        '& .slide': {
            background: '#FFF8DC',
        },

        '& .control-next:before': {
            borderLeft: '8px solid #425c5a !important',
        },

        '& .control-prev:before': {
            borderRight: '8px solid #425c5a !important',
        },

        '& .control-arrow:hover': {
            background: '#425c5a33 !important',
        },

        '& .dot': {
            background: '#425c5a !important',
        },
    },
    button: {
        textDecoration: 'none',
        borderRadius: 15,
        padding: '7px 15px',
        background: '#019e01',
        color: '#fff',
        width: 'fit-content',
        marginBottom: 30,
        transition: '.3s all',
        display: 'block',
        float: 'right',

        '&:hover': {
            background: '#425c5a',
        },
    },
    card: {
        width: '75%',
        margin: '0 auto',
        padding: '50px 0 30px',
        minHeight: 'calc(100vh - 140px)',
        display: 'flex',
        alignItems: 'flex-start',
        height: '100%',
    },
    img: {
        maxWidth: 250,
        maxHeight: 250,
        margin: '6px 0',
    },
    right: {
        float: 'right',
        marginLeft: 15,
    },
    left: {
        float: 'left',
        marginRight: 15,
    },
    text: {
        fontSize: 18,
        textAlign: 'justify',
        lineHeight: '24px',
        marginBottom: 20,
    },
    images: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    small: {
        maxWidth: 250,
        maxHeight: 150,
    },
    imageTitle: {
        textTransform: 'uppercase',
        color: '#314644',
        fontWeight: 500,
    },
    imageContainer: {
        margin: '10px 0',
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: 10,
        textAlign: 'left',
    },
    titleItalic: {
        fontSize: 34,
        fontWeight: 700,
        fontStyle: 'italic',
        marginTop: 15,
    },
    list: {
        listStyleType: 'disc',
        marginBottom: 20,
        paddingLeft: 18,
    },
    item: {
        fontSize: 18,
        textAlign: 'justify',
        lineHeight: '24px',
    },
};
