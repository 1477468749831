export const PRODUCTS_LANGUAGES = {
    ru: {
        buttonToContactUs: 'Сделать заказ',
    },
    en: {
        buttonToContactUs: 'Make an order',
    },
    ua: {
        buttonToContactUs: 'Зробити заказ',
    },
};
