import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'ElectronicsPage',
});

export const ElectronicsPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_8_1.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Каждому из нас трудно представить свою жизнь без технологий и то, что еще 20 лет назад казалось фантастикой сегодня является неотъемлемой часть быта. Будь то смартфон, ноутбук, планшет, или любой другой гаджет, все это стало привычным и доступным.'
                                            : lang === 'ua'
                                            ? "Кожному з нас важко уявити своє життя без технологій і те, що ще 20 років тому здавалося фантастикою сьогодні є невід'ємною частина побуту. Будь то смартфон, ноутбук, планшет, або будь-який інший гаджет, все це стало звичним і доступним."
                                            : 'It is difficult for each of us to imagine our life without technology, and what seemed fantastic 20 years ago is now an integral part of everyday life. Whether it be a smartphone, laptop, tablet, or any other gadget, it has all become familiar and accessible.'}
                                    </div>
                                    <div className={classes.title} style={{ textAlign: 'left' }}>
                                        {lang === 'ru'
                                            ? 'Наша цель – помочь Вам не затеряться в разнообразии мира техники!'
                                            : lang === 'ua'
                                            ? 'Наша мета - допомогти Ви не загубитися в розмаїтті світу техніки!'
                                            : 'Our goal is to help you not to get lost in the diversity of the world of technology!'}
                                    </div>
                                    <div className={classes.title} style={{ textAlign: 'left' }}>
                                        {lang === 'ru'
                                            ? 'Мы не стоим на месте – мы двигаемся Вам на встречу!'
                                            : lang === 'ua'
                                            ? 'Ми не стоїмо на місці - ми рухаємося Вам на зустріч!'
                                            : 'We are not standing still - we are moving towards you!'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Мы верим, что техника – это не просто корпус и железные детали. У нее есть душа и она способна изменить мир к лучшему.'
                                            : lang === 'ua'
                                            ? 'Ми віримо, що техніка - це не просто корпус і залізні деталі. У неї є душа і вона здатна змінити світ на краще.'
                                            : 'We believe that technology is not just a body and iron parts. She has a soul and is capable of changing the world for the better.'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_8_2.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'NOLANTO - мульти брендовый продавец компьютерной техники от известных американских, европейских и азиатских производителей. С нашей помощью Вы сможете купить компьютерную технику, которая будет соответствовать наивысшим стандартам качества, прослужит максимальный период пользования и порадует удобным функционалом.'
                                            : lang === 'ua'
                                            ? "NOLANTO - мульти брендовий продавець комп'ютерної техніки від відомих американських, європейських і азіатських виробників. З нашою допомогою Ви зможете купити комп'ютерну техніку, яка буде відповідати найвищим стандартам якості, прослужить максимальний період користування і порадує зручним функціоналом."
                                            : 'NOLANTO is a multi-brand seller of computer equipment from well-known American, European and Asian manufacturers. With our help, you can buy computer equipment that will meet the highest quality standards, will last the maximum period of use and will delight you with convenient functionality.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Наш ассортимент состоит из различных типов ноутбуков, компьютеров и аксессуаров.'
                                            : lang === 'ua'
                                            ? "Наш асортимент складається з різних типів ноутбуків, комп'ютерів і аксесуарів."
                                            : 'Our range consists of various types of laptops, computers and accessories.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Мы собрали лучшие предложения компьютерной техники в сегменте действующего рынка. Таким образом, каждый покупатель сможет у нас найти подходящее устройство для работы, для игр или же для использования в качестве домашнего компьютера.'
                                            : lang === 'ua'
                                            ? "Ми зібрали кращі пропозиції комп'ютерної техніки в сегменті діючого ринку. Таким чином, кожен покупець зможе у нас знайти підходяще пристрій для роботи, для ігор або ж для використання в якості домашнього комп'ютера."
                                            : 'We have collected the best offers of computer technology in the segment of the current market. Thus, each customer will be able to find a suitable device for work, for games, or for use as a home computer.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Компания NOLANTO за короткий срок уже успела зарекомендовать себя, как надежного и проверенного партнера, поставщика оргтехники, комплектующих и расходных материалов.'
                                            : lang === 'ua'
                                            ? 'Компанія NOLANTO за короткий термін вже встигла зарекомендувати себе, як надійного і перевіреного партнера, постачальника оргтехніки, комплектуючих і витратних матеріалів.'
                                            : 'In a short period of time, NOLANTO has already established itself as a reliable and trusted partner, supplier of office equipment, accessories and consumables.'}
                                    </div>
                                    <div className={classes.title} style={{ textAlign: 'left' }}>
                                        {lang === 'ru'
                                            ? 'НАШИ ПРЕИМУЩЕСТВА:'
                                            : lang === 'ua'
                                            ? 'НАШІ ПЕРЕВАГИ:'
                                            : 'OUR ADVANTAGES:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Опытный, профессиональный и активный коллектив;'
                                                : lang === 'ua'
                                                ? 'Досвідчений, професійний і активний колектив;'
                                                : 'Experienced, professional and active team;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Сотрудничество с производителями мировых брендов или их официальными представителями;'
                                                : lang === 'ua'
                                                ? 'Співпраця з виробниками світових брендів або їх офіційними представниками;'
                                                : 'Cooperation with manufacturers of world brands or their official representatives;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Современная методы логистики;'
                                                : lang === 'ua'
                                                ? 'Сучасна методи логістики;'
                                                : 'Modern methods of logistics;'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Всегда позитивное и оптимистическое настроение! Мы рады каждому клиенту!'
                                                : lang === 'ua'
                                                ? 'Завжди позитивне і оптимістичний настрій! Ми раді кожному клієнту!'
                                                : 'Always a positive and optimistic mood! We are glad to every client!'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'ММы нацелены на результат! Работаем для вас!'
                                                : lang === 'ua'
                                                ? 'Ми націлені на результат! Працюємо для вас!'
                                                : 'We are focused on results! We work for you!'}
                                        </li>
                                    </ul>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_1_19.jpg`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <br />
                                    <div className={classes.title} style={{ marginTop: 10 }}>
                                        {lang === 'ru'
                                            ? 'NOLANTO – ЛУЧШИЕ КОМПЬЮТЕРЫ ЗА СВОЮ ЦЕНУ!'
                                            : lang === 'ua'
                                            ? "NOLANTO - НАЙКРАЩІ КОМП'ЮТЕРИ ЗА СВОЮ ЦІНУ!"
                                            : 'NOLANTO - THE BEST COMPUTERS FOR YOUR PRICE!'}
                                    </div>
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Мы всегда заботимся о своих клиентах, как настоящих, так и будущих, поэтому всегда идем навстречу покупателям и предоставляем выгодные условия сотрудничества.'
                                            : lang === 'ua'
                                            ? 'Ми завжди дбаємо про своїх клієнтів, як справжніх, так і майбутніх, тому завжди йдемо назустріч покупцям і надаємо вигідні умови співпраці.'
                                            : 'We always care about our customers, both present and future, so we always meet customers halfway and provide favorable terms of cooperation.'}
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

ElectronicsPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
