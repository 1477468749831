import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createElementsFromText } from 'html-text-to-react';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'LegalConsultingPage',
});

export const LegalConsultingPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Наша компания предлагает Вам квалифицированную помощь для старта Вашей деятельности в Украине.'
                                            : lang === 'ua'
                                            ? 'Наша компанія пропонує Вам кваліфіковану допомогу для старту Вашої діяльності в Україні.'
                                            : 'Our company offers you qualified assistance to start your business in Ukraine.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Основная задача нашей компании – комплексная поддержка иностранных инвесторов, которые собираются или уже ведут деятельность на рынке Украины, а именно: предоставление широкого спектра юридических, бухгалтерских, кадровых услуг, начиная от вводной консультации и решения организационных вопросов для старта Вашего бизнеса и заканчивая комплексным сопровождением хозяйственной деятельности. '
                                            : lang === 'ua'
                                            ? 'Основне завдання нашої компанії - комплексна підтримка іноземних інвесторів, які збираються або вже ведуть діяльність на ринку України, а саме: надання широкого спектру юридичних, бухгалтерських, кадрових послуг, починаючи від вступної консультації та вирішення організаційних питань для старту Вашого бізнесу і закінчуючи комплексним супроводом господарської діяльності.'
                                            : 'The main task of our company is comprehensive support of foreign investors who are going to or are already operating in the Ukrainian market, namely: providing a wide range of legal, accounting, personnel services, ranging from introductory consultation and solving organizational issues for starting your business and ending with comprehensive support of economic activities.'}
                                    </div>
                                    <div className={classes.text}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_6_1.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                        />
                                        <div className={classes.title}>
                                            {lang === 'ru'
                                                ? 'Наши юристы успешно практикуют в сфере международной торговли и качественно оказывают следующие услуги:'
                                                : lang === 'ua'
                                                ? 'Наші юристи успішно практикують в сфері міжнародної торгівлі і якісно надають такі послуги:'
                                                : 'Our lawyers successfully practice in the field of international trade and provide the following services of high quality:'}
                                        </div>
                                        <ul className={classes.list}>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Регистрация и сопровождение зарубежных компаний в Украине;'
                                                    : lang === 'ua'
                                                    ? 'Реєстрація та супровід зарубіжних компаній в Україні;'
                                                    : 'Registration and support of foreign companies in Ukraine;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Открытие счетов в банках Украины;'
                                                    : lang === 'ua'
                                                    ? 'Відкриття рахунків в банках України;'
                                                    : 'Opening accounts in banks of Ukraine;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Cоставление и анализ различных видов внешнеэкономических договоров и контрактов;'
                                                    : lang === 'ua'
                                                    ? 'Складання та аналіз різних видів зовнішньоекономічних договорів і контрактів;'
                                                    : 'Drafting and analysis of various types of foreign economic agreements and contracts;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Консультирование и сопровождение транснациональных сделок как для украинских, так и для иностранных клиентов и др.;'
                                                    : lang === 'ua'
                                                    ? 'Консультування та супровід транснаціональних угод як для українських, так і для іноземних клієнтів і ін .;'
                                                    : 'Consulting and support of transnational transactions for both Ukrainian and foreign clients, etc .;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Международный коммерческий арбитраж — защита интересов клиентов при решении споров в сфере международной коммерции;'
                                                    : lang === 'ua'
                                                    ? 'Міжнародний комерційний арбітраж - захист інтересів клієнтів при вирішенні спорів у сфері міжнародної комерції;'
                                                    : 'International commercial arbitration - protecting the interests of clients in resolving disputes in the field of international commerce;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Подготовка и сопровождение переговоров с иностранными контрагентами;'
                                                    : lang === 'ua'
                                                    ? 'Підготовка та супровід переговорів з іноземними контрагентами;'
                                                    : 'Preparation and support of negotiations with foreign contractors;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Сопровождение выполнения международных торговых контрактов;'
                                                    : lang === 'ua'
                                                    ? 'Супровід виконання міжнародних торгових контрактів;'
                                                    : 'Support in the implementation of international trade contracts;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'Сопровождение экспортно-импортных торговых операций.'
                                                    : lang === 'ua'
                                                    ? 'Супровід експортно-імпортних торговельних операцій.'
                                                    : 'Support of export-import trade operations.'}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_6_2.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Наши клиенты – это компании и частные лица. И каждому из них мы стараемся предложить  максимально выгодное и удобное решение, которое будет отвечать задачам его бизнеса. Мы поддерживаем партнерские отношения с ведущими юридическими, финансовыми компаниями, банками и другими организациями в различных сферах деятельности.'
                                            : lang === 'ua'
                                            ? 'Наші клієнти - це компанії і приватні особи. І кожному з них ми намагаємося запропонувати максимально вигідне і зручне рішення, яке буде відповідати завданням його бізнесу. Ми підтримуємо партнерські відносини з провідними юридичними, фінансовими компаніями, банками та іншими організаціями в різних сферах діяльності.'
                                            : 'Our clients are companies and individuals. And we try to offer each of them the most profitable and convenient solution that will meet the tasks of his business. We maintain partnerships with leading legal, financial companies, banks and other organizations in various fields of activity.'}
                                    </div>
                                    <div className={classes.text}>
                                        {createElementsFromText(
                                            lang === 'ru'
                                                ? 'Абонентское юридическое обслуживание от NOLANTO позволяет оперативно реагировать и устранять насущные проблемы в короткое время и за приемлемую цену. Мы активно работаем в направлении комплексного обслуживания клиентов.'
                                                : lang === 'ua'
                                                ? 'Абонентське юридичне обслуговування від NOLANTO дозволяє оперативно реагувати і усувати насущні проблеми в короткий час і за прийнятну ціну. Ми активно працюємо в напрямку комплексного обслуговування клієнтів.'
                                                : 'Subscriber legal service from NOLANTO allows you to respond quickly and eliminate pressing problems in a short time and at an affordable price. We are actively working towards comprehensive customer service.',
                                        )}
                                    </div>
                                    <div className={classes.title} style={{ fontWeight: 400 }}>
                                        <b>
                                            {lang === 'ru'
                                                ? 'Гарантируем конфиденциальность информации, '
                                                : lang === 'ua'
                                                ? 'Гарантуємо конфіденційність інформації, '
                                                : 'We guarantee the confidentiality of information '}
                                        </b>
                                        {lang === 'ru'
                                            ? 'которую доверяет нам клиент.'
                                            : lang === 'ua'
                                            ? 'яку довіряє нам клієнт.'
                                            : 'that the client entrusts to us.'}
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                    <div className={classes.titleItalic} style={{ marginTop: 35 }}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

LegalConsultingPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
