export const styles = {
    root: {
        width: '85%',
        margin: '0 auto',
        padding: '30px 0',
    },
    home: {
        marginBottom: 30,
    },
    title: {
        color: '#314644',
        textAlign: 'center',
        margin: '0 0 20px 0',
        fontSize: '32px',
        fontWeight: 700,
    },
    text: {
        fontSize: 18,
        lineHeight: '28px',
        textAlign: 'justify',
    },
    products: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap',
        marginBottom: 30,
    },
    product: {
        margin: 15,
        outline: 'none',
        textDecoration: 'none',
        width: 200,
        cursor: 'pointer',
        position: 'relative',

        '&:hover': {
            '& > $productImg': {
                transform: 'scale(1.05, 1.05)',
            },

            '& > $productText': {
                textDecoration: 'underline',
            },

            '& $submenuI': {
                opacity: 1,

                '& $product': {
                    display: 'block',
                },

                '@media (max-width: 992px)': {
                    display: 'flex',
                },
            },
        },
    },
    productNoTransform: {
        '&:hover': {
            '& > $productImg': {
                transform: 'none',
            },

            '& > $productText': {
                textDecoration: 'none',
            },
        },
    },
    productImg: {
        borderRadius: '50%',
        width: 150,
        height: 150,
        margin: '0 auto 20px',
        backgroundSize: 'cover',
        overflow: 'hidden',
        transition: 'all .3s',
        backgroundPosition: 'center',
    },
    productText: {
        color: '#000',
        textAlign: 'center',
        fontWeight: 700,
    },
    partnersTitle: {
        color: '#314644',
        textAlign: 'center',
        margin: '0 0 20px 0',
        fontSize: '32px',
        fontWeight: 700,
    },
    partners: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap',
        marginBottom: 30,
    },
    partner: {
        width: 150,
        margin: 15,
    },
    link: {
        outline: 'none',
        display: 'block',
        opacity: 0.7,
        transition: 'all .3s',
        zIndex: 1,

        '&:hover': {
            opacity: 1,
        },

        '@media (max-width: 992px)': {
            opacity: 1,
        },
    },
    partnerImg: {
        maxWidth: '100%',
    },
    submenuI: {
        position: 'absolute',
        top: -130,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '20px 0 0',
        opacity: 0,
        transition: '.5s opacity',

        '@media (max-width: 992px)': {
            display: 'none',
            opacity: 1,
        },

        '& $product': {
            display: 'none',
        },
    },
    submenu: {
        background: '#314644',
        width: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        zIndex: 3,
        borderRadius: 20,

        '& $productImg': {
            width: 50,
            height: 50,
            margin: '0 auto 10px',
        },

        '& $productText': {
            fontSize: 14,
            color: '#fff',
        },

        '& $product': {
            width: 'auto',
        },
    },
    textItalic: {
        textAlign: 'right',
        fontStyle: 'italic',
    },
    boldText: {
        fontWeight: 700,
        textAlign: 'center',
        fontSize: 28,
        marginTop: 20,
    },
};
