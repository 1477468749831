import React from 'react';

import { RotateLoader } from 'react-spinners';
import { createUseStyles } from 'react-jss';

import { styles } from './styles';

const useStyles = createUseStyles(styles, {
    name: 'Loader',
});

export const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <RotateLoader size={15} color="#a2bfbd" />
        </div>
    );
};
