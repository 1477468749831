import React from 'react';

import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { styles } from './styles';
import { withRouter } from 'react-router';

const useStyles = createUseStyles(styles, {
    name: 'FullPrivatePolicyPage',
});

export const FullPrivatePolicyPage = withRouter(
    ({
        match: {
            params: { lang },
        },
    }) => {
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <div className={classes.rootInner}>
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Политика конфиденциальности сайта nolanto.com'
                            : lang === 'en'
                            ? 'Privacy Policy of nolanto.com'
                            : 'Політика конфіденційності сайту nolanto.com'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Этот веб-сайт собирает некоторые персональные данные от своих пользователей.'
                            : lang === 'en'
                            ? 'This Website collects some Personal Data from its Users.'
                            : 'Цей веб-сайт збирає деякі персональні дані від своїх користувачів.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Этот документ можно распечатать для справки, используя команду печати в настройках любого браузера.'
                            : lang === 'en'
                            ? 'This document can be printed for reference by using the print command in the settings of any browser.'
                            : 'Цей документ можна роздрукувати для довідки, використовуючи команду друку в настройках будь-якого браузера.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Владелец и контролер данных'
                            : lang === 'en'
                            ? 'Owner and Data Controller'
                            : 'Власник і контролер даних'}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>Spyrou Kyprianou 61</div>
                        <div className={classes.text}>4003 Limassol</div>
                        <div className={classes.text}>Cyprus</div>
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        <b>
                            {lang === 'ru'
                                ? 'Контактный адрес электронной почты владельца:'
                                : lang === 'en'
                                ? 'Owner contact email:'
                                : 'Контактна адреса електронної пошти власника:'}
                        </b>{' '}
                        nolanto.company@gmail.com
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Типы собираемых данных'
                            : lang === 'en'
                            ? 'Types of Data collected'
                            : 'Типи даних, що збираються'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Среди типов Персональных данных, которые этот Веб-сайт собирает сам или через третьих лиц, есть: имя; фамилия; телефонный номер; Адрес электронной почты; Трекер; Данные об использовании; уникальные идентификаторы устройств для рекламы (например, Google Advertiser ID или IDFA).'
                            : lang === 'en'
                            ? 'Among the types of Personal Data that this Website collects, by itself or through third parties, there are: first name; last name; phone number; email address; Tracker; Usage Data; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example).'
                            : "Серед типів Персональних даних, які цей Веб-сайт збирає сам або через третіх осіб, є: ім'я; прізвище; телефонний номер; Адреса електронної пошти; трекер; Дані про використання; унікальні ідентифікатори пристроїв для реклами (наприклад, Google Advertiser ID або IDFA)."}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Полная информация о каждом типе собираемых Персональных данных представлена в специальных разделах этой политики конфиденциальности или в специальных поясняющих текстах, отображаемых до сбора данных.'
                                : lang === 'en'
                                ? 'Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.'
                                : 'Повна інформація про кожен тип зібраних Персональних даних представлена в спеціальних розділах цієї політики конфіденційності або в спеціальних пояснюючих текстах, що відображаються до збору даних.'}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Персональные данные могут свободно предоставляться пользователем или, в случае данных об использовании, собираться автоматически при использовании этого веб-сайта. Если не указано иное, все данные, запрашиваемые этим веб-сайтом, являются обязательными, и непредоставление этих данных может сделать невозможным предоставление услуг этим веб-сайтом. В случаях, когда на этом веб-сайте прямо указано, что некоторые Данные не являются обязательными, Пользователи могут не передавать эти данные без последствий для доступности или функционирования Сервиса.'
                                : lang === 'en'
                                ? 'Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website. Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.'
                                : "Персональні дані можуть вільно надаватися користувачем або, в разі даних про використання, збиратися автоматично при використанні цього веб-сайту. Якщо не вказано інше, всі дані, запитувані цим веб-сайтом, є обов'язковими, і ненадання цих даних може унеможливити надання послуг цим веб-сайтом. У випадках, коли на цьому веб-сайті прямо вказано, що деякі Дані не є обов'язковими, Користувачі можуть не передавати ці дані без наслідків для доступності або функціонування Сервісу."}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Пользователи, которые не уверены в том, какие Персональные данные являются обязательными, могут связаться с Владельцем.'
                                : lang === 'en'
                                ? 'Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.'
                                : "Користувачі, які не впевнені в тому, які Персональні дані є обов'язковими, можуть зв'язатися з Власником."}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Любое использование файлов cookie - или других инструментов отслеживания - этим Веб-сайтом или владельцами сторонних сервисов, используемых на этом Веб-сайте, служит цели предоставления Услуги, требуемой Пользователем, в дополнение к любым другим целям, описанным в настоящем документе. и в Политике использования файлов cookie, если таковая имеется.'
                                : lang === 'en'
                                ? 'Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.'
                                : 'Будь-яке використання файлів cookie - або інших інструментів відстеження - цим Веб-сайтом або власниками сторонніх сервісів, які використовуються на цьому Веб-сайті, має на меті надання Послуги, необхідної Користувачем, на додаток до будь-яким іншим цілям, описаним в цьому документі. і в Політиці використання файлів cookie, якщо така є.'}
                        </div>
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Пользователи несут ответственность за любые сторонние персональные данные, полученные, опубликованные или переданные через этот веб-сайт, и подтверждают, что у них есть согласие третьей стороны на предоставление данных владельцу.'
                            : lang === 'en'
                            ? "Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner."
                            : 'Користувачі несуть відповідальність за будь-які сторонні персональні дані, отримані, опубліковані або передані через цей веб-сайт, і підтверджують, що у них є згода третьої сторони на надання даних власнику.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Способ и место обработки Данных'
                            : lang === 'en'
                            ? 'Mode and place of processing the Data'
                            : 'Спосіб і місце обробки Даних'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Способы обработки'
                            : lang === 'en'
                            ? 'Methods of processing'
                            : 'Cпособи обробки'}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Владелец принимает соответствующие меры безопасности для предотвращения несанкционированного доступа, раскрытия, изменения или несанкционированного уничтожения Данных.'
                                : lang === 'en'
                                ? 'The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.'
                                : 'Власник приймає відповідні заходи безпеки для запобігання несанкціонованого доступу, розкриття, зміни або несанкціонованого знищення Даних.'}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Обработка Данных осуществляется с использованием компьютеров и / или ИТ-инструментов с соблюдением организационных процедур и режимов, строго связанных с указанными целями. Помимо Владельца, в некоторых случаях Данные могут быть доступны определенным типам лиц, ответственных за работу этого Веб-сайта (администрирование, продажи, маркетинг, юридические вопросы, системное администрирование) или внешним сторонам (таким как третьи- сторонние поставщики технических услуг, почтовые перевозчики, хостинг-провайдеры, ИТ-компании, коммуникационные агентства), назначенные, при необходимости, обработчиками данных Владельцем. Обновленный список этих сторон может быть запрошен у Владельца в любое время.'
                                : lang === 'en'
                                ? 'The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.'
                                : "Обробка Даних здійснюється з використанням комп'ютерів та / або ІТ-інструментів з дотриманням організаційних процедур і режимів, суворо пов'язаних із зазначеними цілями. Крім Власника, в деяких випадках Дані можуть бути доступні певним типам осіб, відповідальних за роботу цього Веб-сайту (адміністрування, продажу, маркетинг, юридичні питання, системне адміністрування) або зовнішнім сторонам (таким як треті незалежні постачальники технічних послуг, поштові перевізники, хостинг-провайдери, ІТ-компанії, комунікаційні агентства), призначені, при необхідності, обробника даних Власником. оновлений список цих сторін може бути запитаний у Власника в будь-який час."}
                        </div>
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Правовая основа обработки'
                            : lang === 'en'
                            ? 'Legal basis of processing'
                            : 'Правова основа обробки'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Владелец может обрабатывать Персональные данные, относящиеся к Пользователям, если применимо одно из следующих условий:'
                            : lang === 'en'
                            ? 'The Owner may process Personal Data relating to Users if one of the following applies:'
                            : 'Власник може обробляти Персональні дані, які стосуються Користувачам, якщо є одна з таких умов:'}
                    </div>
                    <ul className={classes.list}>
                        <li>
                            {lang === 'ru'
                                ? 'Пользователи дали свое согласие для одной или нескольких конкретных целей. Примечание. В соответствии с законодательством некоторых стран Владельцу может быть разрешено обрабатывать Персональные данные до тех пор, пока Пользователь не возразит против такой обработки («отказ»), без необходимости полагаться на согласие или любую другую из следующих юридических оснований. Это, однако, не применяется, если обработка Персональных данных регулируется европейским законодательством о защите данных;'
                                : lang === 'en'
                                ? 'Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;'
                                : 'Користувачі дали свою згоду для однієї або декількох конкретних цілей. Примітка. Відповідно до законодавства деяких країн Власнику може бути дозволено обробляти Персональні дані до тих пір, поки Користувач не висловить своє заперечення проти такої обробки («відмова»), без необхідності покладатися на згоду або будь-яку іншу з наступних юридичних підстав. Це, однак, не застосовується, якщо обробка Персональних даних регулюється європейським законодавством про захист даних;'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'предоставление Данных необходимо для выполнения соглашения с Пользователем и / или любых его преддоговорных обязательств;'
                                : lang === 'en'
                                ? 'provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;'
                                : "надання Даних необхідно для виконання угоди з Користувачем і / або будь-яких його переддоговірних зобов'язань;"}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'обработка необходима для соблюдения юридических обязательств, которым подчиняется Владелец;'
                                : lang === 'en'
                                ? 'processing is necessary for compliance with a legal obligation to which the Owner is subject;'
                                : "обробка необхідна для дотримання юридичних зобов'язань, яким підкоряється Власник;"}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'обработка связана с задачей, которая выполняется в общественных интересах или при исполнении официальных полномочий, возложенных на Владельца;'
                                : lang === 'en'
                                ? 'processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;'
                                : "обробка пов'язана з завданням, яка виконується в громадських інтересах або при виконанні офіційних повноважень, покладених на Власника;"}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'обработка необходима для целей законных интересов, преследуемых Владельцем или третьей стороной.'
                                : lang === 'en'
                                ? 'processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.'
                                : 'обробка необхідна для цілей законних інтересів, переслідуваних Власником або третьою стороною.'}
                        </li>
                    </ul>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'В любом случае Владелец с радостью поможет прояснить конкретную правовую основу, которая применяется к обработке, и, в частности, является ли предоставление Персональных данных законодательным или договорным требованием или требованием, необходимым для заключения договора.'
                            : lang === 'en'
                            ? 'In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.'
                            : 'У будь-якому випадку Власник з радістю допоможе прояснити конкретну правову основу, яка застосовується до обробки, і, зокрема, чи є надання Персональних даних законодавчим або договірним вимогою або вимогою, необхідним для укладення договору.'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru' ? 'Место' : lang === 'en' ? 'Place' : 'Місце'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Данные обрабатываются в операционных офисах Владельца и в любых других местах, где находятся стороны, участвующие в обработке.'
                            : lang === 'en'
                            ? "The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located."
                            : 'Дані обробляються в операційних офісах Власника і в будь-яких інших місцях, де знаходяться боку, беруть участь в обробці.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'В зависимости от местоположения пользователя передача данных может включать передачу данных пользователя в страну, отличную от его собственной. Чтобы узнать больше о месте обработки таких переданных Данных, Пользователи могут проверить раздел, содержащий подробную информацию об обработке Личных данных.'
                            : lang === 'en'
                            ? "Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data."
                            : 'Залежно від місця розташування користувача передача даних може включати передачу даних користувача в країну, відмінну від його власної. Щоб дізнатися більше про місце обробки таких переданих Даних, Користувачі можуть перевірити розділ, який містить детальну інформацію про обробку Особистих даних.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Пользователи также имеют право узнать о правовых основах передачи данных в страну за пределами Европейского Союза или в любую международную организацию, регулируемую международным публичным правом или учрежденную двумя или более странами, такими как ООН, и о принятых мерах безопасности. Владельцем для защиты своих Данных.'
                            : lang === 'en'
                            ? 'Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.'
                            : 'Користувачі також мають право дізнатися про правові основи передачі даних в країну за межами Європейського Союзу або в будь-яку міжнародну організацію, регульовану міжнародним публічним правом або засновану двома або більше країнами, такими як ООН, і про вжиті заходи безпеки. Власником для захисту своїх Даних.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Если такая передача имеет место, Пользователи могут узнать больше, проверив соответствующие разделы этого документа или запросив Владельца, используя информацию, указанную в разделе контактов.'
                            : lang === 'en'
                            ? 'If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.'
                            : 'Якщо така передача має місце, Користувачі можуть дізнатися більше, перевіривши відповідні розділи цього документа або надіславши запит Власника, використовуючи інформацію, зазначену в розділі контактів.'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru' ? 'Время удерживания' : lang === 'en' ? 'Retention time' : 'Час утримування'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Персональные данные должны обрабатываться и храниться столько времени, сколько требуется в соответствии с целью, для которой они были собраны.'
                            : lang === 'en'
                            ? 'Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.'
                            : 'Персональні дані повинні оброблятися і зберігатися стільки часу, скільки потрібно відповідно до мети, для якої вони були зібрані.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru' ? 'Следовательно:' : lang === 'en' ? 'Therefore:' : 'Oтже:'}
                    </div>
                    <ul className={classes.list}>
                        <li>
                            {lang === 'ru'
                                ? 'Персональные данные, собранные для целей, связанных с выполнением договора между Владельцем и Пользователем, должны храниться до тех пор, пока такой договор не будет полностью выполнен.'
                                : lang === 'en'
                                ? 'Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.'
                                : "Персональні дані, зібрані для цілей, пов'язаних з виконанням договору між Власником та Користувачем, повинні зберігатися до тих пір, поки такий договір не буде повністю виконаний."}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? '•Персональные данные, собранные в целях соблюдения законных интересов Владельца, должны храниться до тех пор, пока это необходимо для выполнения таких целей. Пользователи могут найти конкретную информацию о законных интересах, преследуемых Владельцем, в соответствующих разделах этого документа или связавшись с Владельцем.'
                                : lang === 'en'
                                ? 'Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.'
                                : "Персональні дані, зібрані з метою дотримання законних інтересів Власника, повинні зберігатися до тих пір, поки це необхідно для виконання таких цілей. Користувачі можуть знайти конкретну інформацію про законні інтереси, переслідуваних Власником, в відповідних розділах цього документа або зв'язавшись з Власником."}
                        </li>
                    </ul>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Владельцу может быть разрешено хранить Персональные данные в течение более длительного периода всякий раз, когда Пользователь дал согласие на такую обработку, если такое согласие не отозвано. Кроме того, Владелец может быть обязан хранить Персональные данные в течение более длительного периода, когда это необходимо для выполнения юридического обязательства или по приказу властей.'
                            : lang === 'en'
                            ? 'The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.'
                            : "Власнику може бути дозволено зберігати Персональні дані протягом більш тривалого періоду щоразу, коли Користувач дав згоду на таку обробку, якщо така згода не відкликано. Крім того, Власник може бути зобов'язаний зберігати Персональні дані протягом більш тривалого періоду, коли це необхідно для виконання юридичного зобов'язання або за наказом влади."}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'По истечении срока хранения Персональные данные удаляются. Следовательно, право на доступ, право на удаление, право на исправление и право на переносимость данных не могут быть реализованы после истечения срока хранения.'
                            : lang === 'en'
                            ? 'Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.'
                            : 'Після закінчення терміну зберігання Персональні дані видаляються. Отже, право на доступ, право на видалення, право на виправлення і право на переносимість даних не можуть бути реалізовані після закінчення терміну зберігання.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Цели обработки'
                            : lang === 'en'
                            ? 'The purposes of processing'
                            : 'цілі обробки'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Данные, касающиеся Пользователя, собираются, чтобы позволить Владельцу предоставлять свои Услуги, выполнять свои юридические обязательства, отвечать на запросы о принудительном исполнении, защищать свои права и интересы (или права и интересы своих Пользователей или третьих лиц), обнаруживать любые злонамеренные или мошеннические действия, а также следующее: общение с пользователем, аналитика, ремаркетинг и поведенческий таргетинг, реклама, отображение контента с внешних платформ, управление тегами, защита от спама и оптимизация и распространение трафика.'
                            : lang === 'en'
                            ? 'The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Contacting the User, Analytics, Remarketing and behavioral targeting, Advertising, Displaying content from external platforms, Tag Management, SPAM protection and Traffic optimization and distribution.'
                            : "Дані, що стосуються Користувача, збираються, щоб дозволити Власнику надавати свої Послуги, виконувати свої юридичні зобов'язання, відповідати на запити про примусове виконання, захищати свої права та інтереси (або права і інтереси своїх користувачів або третіх осіб), виявляти будь-які зловмисні або шахрайські дії, а також наступне: спілкування з користувачем, аналітика, ремаркетинг та поведінковий таргетинг, реклама, відображення контенту з зовнішніх платформ, для керування позначками, захист від спаму та оптимізація та поширення трафіку."}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Для получения конкретной информации о Персональных данных, используемых для каждой цели, Пользователь может обратиться к разделу «Подробная информация об обработке Персональных данных».'
                            : lang === 'en'
                            ? 'For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.'
                            : 'Для отримання конкретної інформації про Персональних даних, що використовуються для кожної цілі, Користувач може звернутися до розділу «Детальна інформація про обробку Персональних даних».'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Подробная информация об обработке Персональных данных'
                            : lang === 'en'
                            ? 'Detailed information on the processing of Personal Data'
                            : 'Детальна інформація про обробку Персональних даних'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Персональные данные собираются для следующих целей и с использованием следующих сервисов:'
                            : lang === 'en'
                            ? 'Personal Data is collected for the following purposes and using the following services:'
                            : 'Персональні дані збираються для таких цілей і з використанням наступних сервісів:'}
                    </div>
                    <ul className={classes.list}>
                        <li>{lang === 'ru' ? 'Реклама' : lang === 'en' ? 'Advertising' : 'Реклама'}</li>
                        <li>{lang === 'ru' ? 'Аналитика' : lang === 'en' ? 'Analytics' : 'Аналітика'}</li>
                        <li>
                            {lang === 'ru'
                                ? 'Связь с пользователем'
                                : lang === 'en'
                                ? 'Contacting the User'
                                : "Зв'язок з користувачем"}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Отображение контента с внешних платформ'
                                : lang === 'en'
                                ? 'Displaying content from external platforms'
                                : 'Відображення контенту з зовнішніх платформ'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Ремаркетинг и поведенческий таргетинг'
                                : lang === 'en'
                                ? 'Remarketing and behavioral targeting'
                                : 'Ремаркетінг і поведінковий таргетинг'}
                        </li>
                        <li>
                            {lang === 'ru' ? 'Защита от спама' : lang === 'en' ? 'SPAM protection' : 'Захист від спаму'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Управление тегами'
                                : lang === 'en'
                                ? 'Tag Management'
                                : 'Управління тегами'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Оптимизация и распределение трафика'
                                : lang === 'en'
                                ? 'Traffic optimization and distribution'
                                : 'Оптимізація і розподіл трафіку'}
                        </li>
                    </ul>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Права пользователей'
                            : lang === 'en'
                            ? 'The rights of Users'
                            : 'Права користувачів'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Пользователи могут осуществлять определенные права в отношении своих Данных, обрабатываемых Владельцем.'
                            : lang === 'en'
                            ? 'Users may exercise certain rights regarding their Data processed by the Owner.'
                            : 'Користувачі можуть здійснювати певні права щодо своїх Даних, оброблюваних Власником.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'В частности, Пользователи имеют право делать следующее:'
                            : lang === 'en'
                            ? 'In particular, Users have the right to do the following:'
                            : 'Зокрема, Користувачі мають право робити наступне:'}
                    </div>
                    <ul className={classes.list}>
                        <li>
                            {lang === 'ru'
                                ? 'Отозвать свое согласие можно в любой момент. Пользователи имеют право отозвать согласие, если они ранее дали согласие на обработку своих Персональных данных.'
                                : lang === 'en'
                                ? 'Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.'
                                : 'Відкликати свою згоду можна в будь-який момент. Користувачі мають право відкликати згоду, якщо вони раніше дали згоду на обробку своїх Персональних даних.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Возражать против обработки своих Данных. Пользователи имеют право возражать против обработки своих Данных, если обработка осуществляется на законных основаниях, кроме согласия. Более подробная информация представлена в соответствующем разделе ниже.'
                                : lang === 'en'
                                ? 'Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.'
                                : 'Заперечувати проти обробки своїх Даних. Користувачі мають право заперечувати проти обробки своїх Даних, якщо обробка здійснюється на законних підставах, крім згоди. Більш детальна інформація представлена у відповідному розділі нижче.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Доступ к их данным. Пользователи имеют право узнать, обрабатываются ли Данные Владельцем, получить раскрытие информации относительно определенных аспектов обработки и получить копию Данных, находящихся в процессе обработки.'
                                : lang === 'en'
                                ? 'Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.'
                                : 'Доступ до їх даними. Користувачі мають право дізнатися, обробляються чи Дані Власником, отримати розкриття інформації щодо певних аспектів обробки і отримати копію Даних, які перебувають в процесі обробки.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Проверить и найти исправление. Пользователи имеют право проверять точность своих Данных и запрашивать их обновление или исправление.'
                                : lang === 'en'
                                ? 'Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.'
                                : 'Перевірити і знайти виправлення. Користувачі мають право перевіряти точність своїх Даних і запитувати їх оновлення або виправлення.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Ограничить обработку своих Данных. Пользователи имеют право при определенных обстоятельствах ограничить обработку своих Данных. В этом случае Владелец не будет обрабатывать свои Данные для каких-либо целей, кроме их хранения.'
                                : lang === 'en'
                                ? 'Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.'
                                : 'Обмежити обробку своїх Даних. Користувачі мають право за певних обставин обмежити обробку своїх Даних. В цьому випадку Власник не буде обробляти свої Дані для будь-яких цілей, крім їх зберігання.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Удалять или удалять свои личные данные иным образом. Пользователи имеют право при определенных обстоятельствах потребовать удаления своих Данных от Владельца.'
                                : lang === 'en'
                                ? 'Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.'
                                : 'Видаляти або видаляти свої особисті дані іншим чином. Користувачі мають право за певних обставин вимагати видалення своїх Даних від Власника.'}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Получите свои данные и передайте их другому контроллеру. Пользователи имеют право получать свои Данные в структурированном, широко используемом и машиночитаемом формате и, если это технически возможно, беспрепятственно передавать их другому контроллеру. Это положение применяется при условии, что Данные обрабатываются автоматизированными средствами и что обработка основана на согласии пользователя, на контракте, частью которого является Пользователь, или на его преддоговорных обязательствах.'
                                : lang === 'en'
                                ? "Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof."
                                : "Отримайте свої дані і передайте їх іншому контролеру. Користувачі мають право одержувати свої Дані в структурованому, широко використовується і машиночитаемом форматі і, якщо це технічно можливо, безперешкодно передавати їх іншому контролеру. Це положення застосовується за умови, що Дані обробляються автоматизованими засобами і що обробка заснована на згоді користувача, на контракті, частиною якого є Користувач, або на його переддоговірних зобов'язання."}
                        </li>
                        <li>
                            {lang === 'ru'
                                ? 'Подавать жалобу. Пользователи имеют право подать иск в свой компетентный орган по защите данных.'
                                : lang === 'en'
                                ? 'Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.'
                                : 'Подавати скаргу. Користувачі мають право подати позов до свого компетентний орган із захисту даних.'}
                        </li>
                    </ul>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Подробная информация о праве на возражение против обработки'
                            : lang === 'en'
                            ? 'Details about the right to object to processing'
                            : 'Детальна інформація про право на заперечення проти обробки'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Если Персональные данные обрабатываются в общественных интересах, при осуществлении официальных полномочий, возложенных на Владельца, или в целях законных интересов, преследуемых Владельцем, Пользователи могут возражать против такой обработки, указав основание, связанное с их конкретной ситуацией, чтобы Обоснуйте возражение.'
                            : lang === 'en'
                            ? 'Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.'
                            : "Якщо Персональні дані обробляються в громадських інтересах, при здійсненні офіційних повноважень, покладених на Власника, або з метою законних інтересів, переслідуваних Власником, Користувачі можуть заперечувати проти такої обробки, вказавши підставу, пов'язане з їх конкретною ситуацією, щоб Обгрунтуйте заперечення."}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Пользователи должны знать, что, однако, если их Персональные данные будут обрабатываться в целях прямого маркетинга, они могут возразить против такой обработки в любое время без объяснения причин. Чтобы узнать, обрабатывает ли Владелец Персональные данные в целях прямого маркетинга, Пользователи могут обратиться к соответствующим разделам этого документа.'
                            : lang === 'en'
                            ? 'Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.'
                            : 'Користувачі повинні знати, що, однак, якщо їх Персональні дані будуть оброблятися в цілях прямого маркетингу, вони можуть заперечити проти такої обробки в будь-який час без пояснення причин. Щоб дізнатися, обробляє чи Власник Персональні дані в цілях прямого маркетингу, Користувачі можуть звернутися до відповідних розділів цього документа.'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Как реализовать эти права'
                            : lang === 'en'
                            ? 'How to exercise these rights'
                            : 'Як реалізувати ці права'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Любые запросы на осуществление прав пользователя могут быть направлены Владельцу через контактную информацию, указанную в этом документе. Эти запросы могут выполняться бесплатно и будут рассмотрены Владельцем как можно раньше и всегда в течение одного месяца.'
                            : lang === 'en'
                            ? 'Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.'
                            : 'Будь-які запити на здійснення прав користувача можуть бути спрямовані Власнику через контактну інформацію, зазначену в цьому документі. Ці запити можуть виконуватися безкоштовно і будуть розглянуті Власником якомога раніше і завжди протягом одного місяця.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Политика использования файлов cookie'
                            : lang === 'en'
                            ? 'Cookie Policy'
                            : 'Політика використання файлів cookie'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'На этом веб-сайте используются трекеры. Чтобы узнать больше, Пользователь может ознакомиться с Политикой использования файлов cookie .'
                            : lang === 'en'
                            ? 'This Website uses Trackers. To learn more, the User may consult the Cookie Policy.'
                            : 'На цьому веб-сайті використовуються трекери. Щоб дізнатися більше, Користувач може ознайомитися з Політикою використання файлів cookie.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Дополнительная информация о сборе и обработке данных'
                            : lang === 'en'
                            ? 'Additional information about Data collection and processing'
                            : 'Додаткова інформація про збір та обробку даних'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru' ? 'Судебный иск' : lang === 'en' ? 'Legal action' : 'Судовий позов\n'}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Персональные данные Пользователя могут быть использованы Владельцем в юридических целях в суде или на этапах, ведущих к возможным судебным искам, связанным с ненадлежащим использованием этого Веб-сайта или связанных с ним Услуг.'
                                : lang === 'en'
                                ? "The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services."
                                : "Персональні дані Користувача можуть бути використані Власником в юридичних цілях в суді або на етапах, що ведуть до можливих судових позовів, пов'язаних з неналежним використанням цього Веб-сайту або пов'язаних з ним Послуг."}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Пользователь заявляет, что осознает, что от Владельца могут потребовать раскрыть личные данные по запросу государственных органов.'
                                : lang === 'en'
                                ? 'The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.'
                                : 'Користувач заявляє, що усвідомлює, що від Власника можуть зажадати розкрити особисті дані за запитом державних органів.'}
                        </div>
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Дополнительная информация о Персональных данных Пользователя'
                            : lang === 'en'
                            ? "Additional information about User's Personal Data"
                            : 'Додаткова інформація про Персональних даних Користувача'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'В дополнение к информации, содержащейся в этой политике конфиденциальности, этот Веб-сайт может предоставить Пользователю дополнительную и контекстную информацию, касающуюся конкретных Услуг или сбора и обработки Персональных данных по запросу.'
                            : lang === 'en'
                            ? 'In addition to the information contained in this privacy policy, this Website may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.'
                            : 'На додаток до інформації, що міститься в цій політиці конфіденційності, цей Веб-сайт може надати Користувачеві додаткову і контекстну інформацію, що стосується конкретних Послуг або збору і обробки Персональних даних за запитом.'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Системные журналы и обслуживание'
                            : lang === 'en'
                            ? 'System logs and maintenance'
                            : 'Системні журнали і обслуговування'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'В целях эксплуатации и обслуживания этот Веб-сайт и любые сторонние службы могут собирать файлы, в которых регистрируется взаимодействие с этим Веб-сайтом (системные журналы), для этой цели используются другие Личные данные (например, IP-адрес).'
                            : lang === 'en'
                            ? 'For operation and maintenance purposes, this Website and any third-party services may collect files that record interaction with this Website (System logs) use other Personal Data (such as the IP Address) for this purpose.'
                            : 'З метою експлуатації та обслуговування цей Веб-сайт і будь-які сторонні служби можуть збирати файли, в яких реєструється взаємодія з цим Веб-сайтом (системні журнали), для цієї мети використовуються інші Особисті дані (наприклад, IP-адреса).'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Информация, не содержащаяся в этой политике'
                            : lang === 'en'
                            ? 'Information not contained in this policy'
                            : 'Інформація, яка не міститься в цій політиці'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Более подробная информация о сборе или обработке Персональных данных может быть запрошена у Владельца в любое время. См. Контактную информацию в начале этого документа.'
                            : lang === 'en'
                            ? 'More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.'
                            : 'Більш детальна інформація про збір або обробці Персональних даних може бути запрошена у Власника в будь-який час. Див. Контактну інформацію на початку цього документа.'}
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Как обрабатываются запросы «Не отслеживать»'
                            : lang === 'en'
                            ? 'How “Do Not Track” requests are handled'
                            : 'Як обробляються запити «Не відстежувати»'}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Этот веб-сайт не поддерживает запросы «Не отслеживать».'
                                : lang === 'en'
                                ? 'This Website does not support “Do Not Track” requests.'
                                : 'Цей веб-сайт не підтримує запити «Не відстежувати».'}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Чтобы определить, удовлетворяет ли какой-либо из сторонних сервисов запросы «Не отслеживать», ознакомьтесь с их политиками конфиденциальности.'
                                : lang === 'en'
                                ? 'To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.'
                                : 'Щоб визначити, чи задовольняє будь-якої зі сторонніх сервісів запити «Не відстежувати», ознайомтеся з їх політиками конфіденційності.'}
                        </div>
                    </div>
                    <div className={classes.subTitle}>
                        {lang === 'ru'
                            ? 'Изменения в этой политике конфиденциальности'
                            : lang === 'en'
                            ? 'Changes to this privacy policy'
                            : 'Зміни в цій політиці конфіденційності'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Владелец оставляет за собой право вносить изменения в эту политику конфиденциальности в любое время, уведомляя своих пользователей на этой странице и, возможно, на этом веб-сайте и / или - насколько это технически и юридически возможно - отправляя уведомление пользователям через любую доступную контактную информацию владелец. Настоятельно рекомендуется часто проверять эту страницу, ссылаясь на дату последнего изменения, указанную внизу.'
                            : lang === 'en'
                            ? 'The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Website and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.'
                            : 'Власник залишає за собою право вносити зміни в цю політику конфіденційності в будь-який час, повідомляючи своїх користувачів на цій сторінці і, можливо, на цьому веб-сайті та / або - наскільки це технічно і юридично можливо - відправляючи повідомлення користувачам через будь-яку доступну контактну інформацію власник . Настійно рекомендується часто перевіряти цю сторінку, посилаючись на дату останньої зміни, вказаний нижче.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Если изменения затрагивают действия по обработке, выполняемые на основании согласия Пользователя, Владелец должен получить новое согласие от Пользователя, если это необходимо.'
                            : lang === 'en'
                            ? 'Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.'
                            : 'Якщо зміни зачіпають дії по обробці, що виконуються на підставі згоди Користувача, Власник повинен отримати нове згоду від Користувача, якщо це необхідно.'}
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.title}>
                        {lang === 'ru'
                            ? 'Легальная информация'
                            : lang === 'en'
                            ? 'Legal information'
                            : 'Легальна інформація'}
                    </div>
                    <div className={classes.block}>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Это заявление о конфиденциальности было подготовлено на основе положений нескольких законодательных актов, включая ст. 13/14 Регламента (ЕС) 2016/679 (Общий регламент по защите данных).'
                                : lang === 'en'
                                ? 'This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).'
                                : 'Ця заява про конфіденційність було підготовлено на основі положень декількох законодавчих актів, включаючи ст. 13/14 Регламенту (ЄС) 2016/679 (Загальний регламент щодо захисту даних).'}
                        </div>
                        <div className={classes.text}>
                            {lang === 'ru'
                                ? 'Эта политика конфиденциальности относится исключительно к этому Веб-сайту, если в этом документе не указано иное.'
                                : lang === 'en'
                                ? 'This privacy policy relates solely to this Website, if not stated otherwise within this document.'
                                : 'Ця політика конфіденційності відноситься виключно до цього Веб-сайту, якщо в цьому документі не вказано інше.'}
                        </div>
                    </div>
                    <hr className={classes.line} />
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'Обновлено: 13 января 2021 г.'
                            : lang === 'en'
                            ? 'Latest update: January 13, 2021'
                            : 'Оновлено: 13 cічня 2021 р.'}
                    </div>
                    <div className={classNames(classes.block, classes.text)}>
                        {lang === 'ru'
                            ? 'nolanto размещает этот контент и собирает только те персональные данные, которые необходимы для его предоставления.'
                            : lang === 'en'
                            ? 'nolanto hosts this content and only collects the Personal Data strictly necessary for it to be provided.'
                            : 'nolanto розміщує цей вміст і збирає лише Персональні дані, строго необхідні для їх надання.'}
                    </div>
                </div>
            </div>
        );
    },
);

FullPrivatePolicyPage.propTypes = {
    params: propTypes.object,
};
