import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'LumberPage',
});

export const LumberPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_1_10.jpg`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                        />
                                        {lang === 'ru'
                                            ? 'За миллионы лет природа создала уникальный, неповторимый материал - дерево. Человек научился перерабатывать его в готовые изделия - легкую, прочную, легко обрабатываемую древесину c практическими возможностями использования. Люди хотят, чтобы их дома были теплыми зимой, прохладными летом, без химических веществ, чтобы они служили из поколения в поколение и в то же время были красивыми.'
                                            : lang === 'ua'
                                            ? 'За мільйони років природа створила унікальний, неповторний матеріал - дерево. Людина навчилася переробляти його в готові вироби - легку, міцну, легко оброблювану деревину з практичними можливостями використання. Люди хочуть, щоб їх будинки були теплими взимку, прохолодними влітку, без хімічних речовин, щоб вони служили з покоління в покоління і в той же час були красивими.'
                                            : 'For millions of years, nature has created a unique, inimitable material - wood. Man has learned to process it into finished products - light, durable, easily processed wood with practical possibilities of use. People want their homes to be warm in the winter, cool in the summer, without chemicals, so that they will serve for generations and be beautiful at the same time.'}
                                    </div>
                                    <div className={classes.text}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_1.jpg`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                        />
                                        {lang === 'ru'
                                            ? 'Все эти требования обеспечивают только материалы из древесины. В перспективе жизненного цикла, древесина, как строительный материал, является выгодным выбором для окружающей среды. Эта среда не ограничивается только нашим домом и двором, она гораздо шире и глобальнее. Поэтому мы поддерживали и поддерживаем разумное и долговечное использование экологически чистых древесных материалов.'
                                            : lang === 'ua'
                                            ? 'Всі ці вимоги забезпечують тільки матеріали з деревини. У перспективі життєвого циклу, деревина, як будівельний матеріал, є вигідним вибором для навколишнього середовища. Це середовище не обмежується тільки нашим будинком і двором, вона набагато ширше і глобальніше. Тому ми підтримували і підтримуємо розумне і довговічне використання екологічно чистих деревних матеріалів.'
                                            : 'All these requirements are provided only by wood materials. From a life cycle perspective, wood as a building material is a beneficial choice for the environment. This environment is not limited only to our home and yard, it is much broader and more global. Therefore, we have supported and support the intelligent and sustainable use of environmentally friendly wood materials.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Дерево - это один из первых материалов, который начало использовать человечество, поэтому история обработки древесины насчитывает уже много веков. Конечно же, с использованием различных технологий данный процесс значительно изменился и за счет модернизации стал более эффективным. Именно поэтому сегодня в результате деревообработки можно получить широкий спектр продуктов из древесины.'
                                            : lang === 'ua'
                                            ? 'Дерево - це один з перших матеріалів, який почав використовувати людство, тому історія обробки деревини налічує вже багато століть. Звичайно ж, з використанням різних технологій цей процес значно змінився і за рахунок модернізації став більш ефективним. Саме тому сьогодні в результаті деревообробки можна отримати широкий спектр продуктів з деревини.'
                                            : 'Wood is one of the first materials that mankind began to use, so the history of wood processing goes back many centuries. Of course, with the use of various technologies, this process has changed significantly and due to modernization it has become more efficient. That is why today, as a result of woodworking, a wide range of wood products can be obtained.'}
                                    </div>
                                    <div style={{ 'clear': 'both' }} />
                                    <div className={classes.images}>
                                        <div className={classes.imageContainer}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/product_img_1_12.jpg`}
                                                alt="image"
                                                className={classNames(classes.img, classes.small)}
                                            />
                                            <div className={classes.imageTitle}>
                                                {lang === 'ru' ? 'Брус' : lang === 'ua' ? 'Брус' : 'Timber'}
                                            </div>
                                        </div>
                                        <div className={classes.imageContainer}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/product_img_1_3.jpg`}
                                                alt="image"
                                                className={classNames(classes.img, classes.small)}
                                            />
                                            <div className={classes.imageTitle}>
                                                {lang === 'ru' ? 'Доска' : lang === 'ua' ? 'Дошка' : 'Board'}
                                            </div>
                                        </div>
                                        <div className={classes.imageContainer}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/product_img_1_17.jpg`}
                                                alt="image"
                                                className={classNames(classes.img, classes.small)}
                                            />
                                            <div className={classes.imageTitle}>
                                                {lang === 'ru' ? 'Дрова' : lang === 'ua' ? 'Дрова' : 'Firewood'}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ 'clear': 'both' }} />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Мы предлагаем нашим клиентам широчайший ассортимент пиломатериалов разных размеров - свежеспиленные или сушеные, с обработкой и без обработки.'
                                            : lang === 'ua'
                                            ? 'Ми пропонуємо нашим клієнтам найширший асортимент пиломатеріалів різних розмірів - свежеспіленного або сушені, з обробкою і без обробки.'
                                            : 'We offer our clients the widest range of sawn timber in various sizes - freshly sawn or dried, with or without treatment.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'NOLANTO - динамично развивающаяся компания в сфере экспорта пиломатериалов. За годы работы наша компания приобрела бесценный опыт, которым мы готовы поделиться с нашими клиентами.'
                                            : lang === 'ua'
                                            ? 'NOLANTO - динамічно розвивається в сфері експорту пиломатеріалів. За роки роботи наша компанія придбала безцінний досвід, яким ми готові поділитися з нашими клієнтами.'
                                            : 'NOLANTO is a dynamically developing company in the export of sawn timber. Over the years, our company has gained invaluable experience, which we are ready to share with our clients.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_1_18.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.title} style={{ textAlign: 'left' }}>
                                        {lang === 'ru'
                                            ? 'Наша миссия:'
                                            : lang === 'ua'
                                            ? 'Наша місія:'
                                            : 'Our mission:'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Обеспечить долгосрочные отношения между покупателями продукции, владельцами лесов, работниками и вовлеченными сторонами: ответственными работами, ответственными доходами и ответственными инвестициями.'
                                            : lang === 'ua'
                                            ? 'Забезпечити довгострокові відносини між покупцями продукції, власниками лісів, працівниками та залученими сторонами: відповідальними роботами, відповідальними доходами і відповідальними інвестиціями.'
                                            : 'Ensure long-term relationships between product buyers, forest owners, workers and stakeholders: responsible work, responsible income and responsible investment.'}
                                    </div>
                                    <div className={classes.title} style={{ textAlign: 'left', marginTop: 10 }}>
                                        {lang === 'ru'
                                            ? 'Наши ценности:'
                                            : lang === 'ua'
                                            ? 'Наші цінності:'
                                            : 'Our values:'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Самое главное, что у нас есть это наши клиенты. Они очень разные - оптовики строительных пиломатериалов, строители, проектировщики и так далее. В то же время, на наш взгляд, всех их объединяет некоторые общие характеристики - любовь к древесине и природе, планирование и мышление в долгосрочной перспективе, чувство ответственности по отношению к окружающим их людям и, конечно, к нашей планете. Поэтому мы их так ценим, гордимся и не можем разочаровывать. Потому что, благодаря им мы развиваемся и устанавливаем новые цели.'
                                            : lang === 'ua'
                                            ? "Найголовніше, що у нас є це наші клієнти. Вони дуже різні - оптовики будівельних пиломатеріалів, будівельники, проектувальники і так далі. У той же час, на наш погляд, всіх їх об'єднує деякі загальні характеристики - любов до деревини і природі, планування і мислення в довгостроковій перспективі, почуття відповідальності по відношенню до оточуючих їх людей і, звичайно, до нашої планети. Тому ми їх так цінуємо, пишаємося і не можемо розчаровувати. Тому що, завдяки їм ми розвиваємося і встановлюємо нові цілі."
                                            : 'The most important thing is that we have our clients. They are very different - wholesalers of building lumber, builders, designers, and so on. At the same time, in our opinion, they all share some common characteristics - love for wood and nature, planning and thinking in the long term, a sense of responsibility towards the people around them and, of course, towards our planet. That is why we value them so much, are proud and cannot disappoint. Because, thanks to them, we develop and set new goals.'}
                                    </div>
                                    <br />
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Наша главная цель и вознаграждение - это довольный клиент'
                                            : lang === 'ua'
                                            ? 'Наша головна мета і винагороду - це задоволений клієнт'
                                            : 'Our main goal and reward is a satisfied customer'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_1_19.jpg`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Если  вы  ищите   надежного   поставщика   пиломатериалов  из Украины, обращайтесь  к  нам:  наши   менеджеры   подготовят для   вас   коммерческое   предложение,   логисты    рассчитают стоимость доставки товара по всем требованиям, наши юристы подготовят  необходимые   бумаги  для   проведения   операции международного масштаба.'
                                            : lang === 'ua'
                                            ? 'Якщо ви шукайте надійного постачальника пиломатеріалів з України, звертайтеся до нас: наші менеджери підготують для вас комерційну пропозицію, логісти розрахують вартість доставки товару за всіма вимогами, наші юристи підготують необхідні папери для проведення операції міжнародного масштабу.'
                                            : 'If you are looking for a reliable lumber supplier from Ukraine, contact us: our managers will prepare a commercial offer for you, logisticians will calculate the cost of delivery of goods for all requirements, our lawyers will prepare the necessary paperwork for an international operation.'}
                                    </div>
                                    <div className={classes.title} style={{ textAlign: 'left', margin: '10px 0 0' }}>
                                        {lang === 'ru'
                                            ? 'НАШИ ПРЕИМУЩЕСТВА:'
                                            : lang === 'ua'
                                            ? 'НАШІ ПЕРЕВАГИ:'
                                            : 'OUR ADVANTAGES:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Большой опыт в логистике, который дает нам возможность предложить нашим клиентам лучшие способы доставки товаров.'
                                                : lang === 'ua'
                                                ? 'Великий досвід в логістиці, який дає нам можливість запропонувати нашим клієнтам кращі способи доставки товарів.'
                                                : 'Extensive experience in logistics, which enables us to offer our customers the best delivery methods.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Широкий спектр базисов поставки продукции (Инкотермс 2010): FCA, CPT, DAP, FOB, CFR.'
                                                : lang === 'ua'
                                                ? 'Широкий спектр базисів поставки продукції (Інкотермс 2010): FCA, CPT, DAP, FOB, CFR.'
                                                : 'A wide range of product delivery bases (Incoterms 2010): FCA, CPT, DAP, FOB, CFR.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'При каждой загрузке мы следим за тем, чтобы к нашим клиентам поступал только качественный товар.'
                                                : lang === 'ua'
                                                ? 'При кожному завантаженні ми стежимо за тим, щоб до наших клієнтів надходив тільки якісний товар.'
                                                : 'With each download, we make sure that only high-quality goods are delivered to our customers.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Мы решим все вопросы с документооборотом и быстро произведем таможенные процедуры.'
                                                : lang === 'ua'
                                                ? 'Ми вирішимо всі питання з документообігом і швидко зробимо митні процедури.'
                                                : 'We will solve all the paperwork issues and quickly carry out customs procedures.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Мы осуществляем экспорт пиломатериалов контейнерами как по морю, так и по железной дороге.'
                                                : lang === 'ua'
                                                ? 'Ми здійснюємо експорт пиломатеріалів контейнерами як по морю, так і по залізниці.'
                                                : 'We export lumber in containers both by sea and by rail.'}
                                        </li>
                                    </ul>
                                    <div className={classes.titleItalic} style={{ fontSize: 34 }}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

LumberPage.propTypes = {
    lang: propTypes.string,
};
