export const HOME_LANGUAGES = {
    ru: {
        homeTitle: 'O нас',
        homeSentense1: 'Мы стремимся помочь каждому кто работает с нами, достичь его личных целей.',
        homeSentense2:
            'Мы стремимся приблизить мечту каждого из Вас, потому что создаем возможности для любых, даже не возможных решений.',
        homeSentense3: 'Лучшее время для реализации ваших планов – сейчас!',
        homeText1:
            'NOLANTO является амбициозным и целеустремленным предприятием, ориентированным на стабильное и долгосрочное сотрудничество. ',
        homeText2:
            'NOLANTO предоставляет своим клиентам <strong>комплексные долгосрочные услуги</strong> по <strong>поставке различных товаров и услуг.</strong> Чтобы полностью реализовать наш бизнес, мы стремимся оптимизировать различные фазы финансового и логистического процессов в тесной координации с различными вовлеченными сторонами. NOLANTO собирает вокруг себя компании, способные решать нестандартные задачи в современной реальности.',
        homeText3:
            'Видение нашей компании простое и амбициозное: мы стремимся формировать <strong>долгосрочные отношения с клиентами.</strong> Мы делаем это, уделяя особое внимание пониманию требований клиентов, используя рыночный подход, чтобы предоставлять <strong>индивидуальные</strong> услуги, которые могут принести максимальную пользу бизнесу наших клиентов.',
        homeEnd: 'МЫ ЛЮБИМ ВАШ БИЗНЕС',
    },
    en: {
        homeTitle: 'About us',
        homeSentense1: 'We strive to help everyone who works with us to achieve their personal goals.',
        homeSentense2:
            'We strive to bring the dream of each of you closer, because we create opportunities for any, even not possible, solutions.',
        homeSentense3: 'The best time to implement your plans is now!',
        homeText1: 'NOLANTO is an ambitious and purposeful enterprise focused on stable and long-term cooperation.',
        homeText2:
            'NOLANTO provides its clients with <strong>comprehensive long-term services</strong> for <strong>the supply of various goods and services.</strong> To fully realize our business, we strive to optimize the various phases of the financial and logistics processes in close coordination with the various parties involved. NOLANTO gathers around itself companies capable of solving non-standard tasks in modern reality.',
        homeText3:
            "The vision of our company is simple and ambitious: we strive to form <strong>long-term relationships with clients.</strong> We do this with a focus on understanding customer requirements, using a market-based approach to deliver <strong>tailor-made</strong> services that can deliver maximum value to our customers' businesses.",
        homeEnd: 'WE LOVE YOUR BUSINESS',
    },
    ua: {
        homeTitle: 'Про нас',
        homeSentense1: 'Ми прагнемо допомогти кожному хто працює з нами, досягти його особистих цілей.',
        homeSentense2:
            'Ми прагнемо наблизити мрію кожного з Вас, тому що створюємо можливості для будь-яких, навіть не можливих рішень.',
        homeSentense3: 'Кращий час для реалізації ваших планів - зараз!',
        homeText1:
            'NOLANTO є амбітним і цілеспрямованим підприємством, орієнтованим на стабільне і довгострокове співробітництво.',
        homeText2:
            'NOLANTO надає своїм клієнтам <strong>комплексні довгострокові послуги</strong> з <strong>постачання різних товарів і послуг.</strong> Щоб повністю реалізувати наш бізнес, ми прагнемо оптимізувати різні фази фінансової та логістичного процесів в тісній координації з різними залученими сторонами. NOLANTO збирає навколо себе компанії, здатні вирішувати нестандартні завдання в сучасній реальності.',
        homeText3:
            'Бачення нашої компанії просте і амбітне: ми прагнемо формувати <strong>довгострокові відносини з клієнтами.</strong> Ми робимо це, приділяючи особливу увагу розумінню вимог клієнтів, використовуючи ринковий підхід, щоб надавати <strong>індивідуальні</strong> послуги, які можуть принести максимальну користь бізнесу наших клієнтів.',
        homeEnd: 'МИ ЛЮБИМО ВАШ БІЗНЕС',
    },
};
