import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createElementsFromText } from 'html-text-to-react';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'SlagPage',
});

export const SlagPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.title} style={{ fontSize: 22 }}>
                                        {lang === 'ru'
                                            ? 'Поговорим о шлаке'
                                            : lang === 'ua'
                                            ? 'Поговоримо про шлак'
                                            : "Let's talk about slag"}
                                    </div>
                                    <div className={classes.text}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_3_1.jpg`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                        />
                                        {createElementsFromText(
                                            lang === 'ru'
                                                ? '<strong>Доменный шлак</strong> - это побочный продукт процесса производства чугуна, в ходе которого образуется большое количество жидкого шлака. При быстром охлаждении на выходе из доменной печи шлак приобретает <strong>гидравлические свойства</strong> и превращается в пористые гранулы со стекловидной структурой (аморфный кремнезем), известные как гранулированный шлак. Затем гранулы измельчаются в порошок, крупность которого сравнима с размерами цемента. '
                                                : lang === 'ua'
                                                ? '<strong>Доменний шлак</strong> - це побічний продукт процесу виробництва чавуну, в ході якого утворюється велика кількість рідкого шлаку. При швидкому охолодженні на виході з доменної печі шлак набуває <strong>гідравлічні властивості</strong> і перетворюється в пористі гранули з склоподібною структурою (аморфний кремнезем), відомі як гранульований шлак. Потім гранули подрібнюються в порошок, крупність якого порівнянна з розмірами цементу. '
                                                : '<strong>Blast furnace slag</strong> is a by-product of the iron-making process, during which large amounts of molten slag are produced. If rapidly cooled as it leaves the blast furnace, slag acquires <strong>hydraulic properties</strong> and is converted into porous vitreous-structure granules (amorphous silica), known as granulated slag. Granules are then ground to a powder whose fineness is comparable to that of cement. ',
                                        )}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_3_1.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                        />
                                        {createElementsFromText(
                                            lang === 'ru'
                                                ? 'Шлаковый цемент особенно подходит для <strong>строительства специальных конструкций</strong>, требующих <strong>высокой стойкости</strong> к эрозионному воздействию элементов, а также для применения в массовом бетоне. Благодаря <strong>низкой теплоте гидратации</strong> он подходит, например, для <strong>строительства морских сооружений.</strong> '
                                                : lang === 'ua'
                                                ? 'Шлаковий цемент особливо підходить для <strong>будівництва спеціальних конструкцій</strong>, що вимагають <strong>високої стійкості</strong> до ерозійного впливу елементів, а також для застосування в масовому бетоні. Завдяки <strong>низькій теплоті гідратації</strong> він підходить, наприклад, для <strong>будівництва морських споруд.</strong>'
                                                : 'Slag cement is particularly suited for <strong>the construction of special structures</strong> requiring <strong>a high resistance</strong> to the erosive action of the elements, as well as for mass concrete applications. Because of its <strong>low hydration heat</strong>, it is suited, for example, for <strong>the construction of marine structures.</strong>',
                                        )}
                                        <div
                                            className={classes.titleItalic}
                                            style={{
                                                margin: 20,
                                                textDecoration: 'underline',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {lang === 'ru'
                                                ? 'Шлак высоко ценится как прочный, экологически чистый и долговечный заполнитель для бетона.'
                                                : lang === 'ua'
                                                ? 'Slag is highly regarded as a strong, environmentally friendly and durable aggregate for concrete.'
                                                : 'Шлак високо цінується як міцний, екологічно чистий і довговічний заповнювач для бетону.'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_3_2.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Измельченный гранулированный доменный шлак (GGBS) получают путем измельчения доменного шлака в воде или паре. В результате получается стеклообразный гранулированный продукт, который затем сушится и измельчается в мелкий порошок. Этот продукт не только обеспечивает экологические преимущества, но и помогает сократить расходы и обеспечивает долговечность конструкций.'
                                            : lang === 'ua'
                                            ? 'Подрібнений гранульований доменний шлак (GGBS) отримують шляхом подрібнення доменного шлаку в воді або парі. В результаті виходить стеклообразний гранульований продукт, який потім сушиться і подрібнюється в дрібний порошок. Цей продукт не тільки забезпечує екологічні переваги, але і допомагає скоротити витрати і забезпечує довговічність конструкцій.'
                                            : 'Grinded Granular Blast Furnace Slag (GGBS) is produced by grinding blast furnace slag in water or steam. The result is a glassy granular product, which is then dried and ground into a fine powder. This product not only provides environmental benefits, but also helps to reduce costs and ensure structural durability.'}
                                    </div>
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Почему GGBS используется в бетоне?'
                                            : lang === 'ua'
                                            ? 'Чому GGBS використовується в бетоні?'
                                            : 'Why is GGBS used in concrete?'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Благодаря более мелкой пористой структуре бетон GGBS значительно более устойчив к диффузии хлоридов, чем бетон из портландцемента. Для железобетонных конструкций, подверженных воздействию хлоридов, использование GGBS обеспечит повышенную прочность и более длительный срок службы.'
                                            : lang === 'ua'
                                            ? 'Завдяки більш дрібної пористій структурі бетон GGBS значно більш стійкий до дифузії хлоридів, ніж бетон з портландцементу. Для залізобетонних конструкцій, що піддаються впливу хлоридів, використання GGBS забезпечить підвищену міцність і більш тривалий термін служби.'
                                            : 'Due to its finer pore structure, GGBS concrete is significantly more resistant to chloride diffusion than Portland cement concrete. For reinforced concrete structures exposed to chlorides, the use of GGBS will provide increased strength and longer service life.'}
                                    </div>
                                    <div className={classes.title} style={{ marginBottom: 0 }}>
                                        {lang === 'ru'
                                            ? 'Преимущества использования GGBS:'
                                            : lang === 'ua'
                                            ? 'Переваги використання GGBS:'
                                            : 'Benefits of using GGBS:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Лучшая обрабатываемость.'
                                                : lang === 'ua'
                                                ? 'Краща оброблюваність.'
                                                : 'Better workability.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Изготовление, укладка и уплотнение бетона становится проще.'
                                                : lang === 'ua'
                                                ? 'Виготовлення, укладання і ущільнення бетону стає простіше.'
                                                : 'Making, placing and compacting concrete becomes easier.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Более низкая температура в раннем возрасте повышается, что снижает риск термического растрескивания в более крупных порах.'
                                                : lang === 'ua'
                                                ? 'Більш низька температура в ранньому віці підвищується, що знижує ризик термічного розтріскування в більших порах.'
                                                : 'A lower temperature rises at an early age, which reduces the risk of thermal cracking in larger pores.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Высокая стойкость к проникновению хлоридов, что снижает риск коррозии арматуры.'
                                                : lang === 'ua'
                                                ? 'Висока стійкість до проникнення хлоридів, що знижує ризик корозії арматури.'
                                                : 'High resistance to chloride penetration, which reduces the risk of reinforcement corrosion.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Высокая стойкость к воздействию сульфатов и других химикатов.'
                                                : lang === 'ua'
                                                ? 'Висока стійкість до впливу сульфатів та інших хімікатів.'
                                                : 'High resistance to sulfates and other chemicals.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Значительные преимущества устойчивости.'
                                                : lang === 'ua'
                                                ? 'Значні переваги стійкості.'
                                                : 'HSignificant sustainability benefits.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Дает хорошее качество поверхности и улучшает внешний вид.'
                                                : lang === 'ua'
                                                ? 'Дає хорошу якість поверхні і покращує зовнішній вигляд.'
                                                : 'Gives good surface quality and improves appearance.'}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Собственный химический состав портланд-шлакового цемента дает ему несколько преимуществ перед обычным цементом, в том числе:'
                                            : lang === 'ua'
                                            ? 'Власний хімічний склад портланд-шлакового цементу дає йому кілька переваг перед звичайним цементом, в тому числі:'
                                            : "Portland slag cement's own chemical composition gives it several advantages over conventional cement, including:"}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_3_3.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Более экологичный'
                                                : lang === 'ua'
                                                ? 'Більш екологічний'
                                                : 'More environmentally friendly'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Отличная устойчивость к атакам хлоридов и сульфатов'
                                                : lang === 'ua'
                                                ? 'Відмінна стійкість до атак хлоридів і сульфатів'
                                                : 'Excellent resistance to chloride and sulfate attacks'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Низкий риск появления трещин'
                                                : lang === 'ua'
                                                ? 'Низький ризик появи тріщин'
                                                : 'Low risk of cracking'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Максимальная прочность на сжатие'
                                                : lang === 'ua'
                                                ? 'Максимальна міцність на стиск'
                                                : 'Maximum compressive strength'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Улучшенная удобоукладываемость'
                                                : lang === 'ua'
                                                ? 'Покращена легкоукладуваність'
                                                : 'Improved workability'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Лучшая совместимость со всеми типами добавок'
                                                : lang === 'ua'
                                                ? 'Краща сумісність з усіма типами добавок'
                                                : 'Better compatibility with all types of supplements'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Превосходная отделка'
                                                : lang === 'ua'
                                                ? 'Чудова обробка'
                                                : 'Excellent finishing'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Легкость прокачки'
                                                : lang === 'ua'
                                                ? 'Легкість прокачування'
                                                : 'Ease of pumping'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Лучшая устойчивость к щелочно-кремнеземной реакции'
                                                : lang === 'ua'
                                                ? 'Краща стійкість до лужно-кремнеземної реакції'
                                                : 'Better resistance to alkali-silica reaction'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Минимальные усадочные трещины'
                                                : lang === 'ua'
                                                ? 'Мінімальні усадочні тріщини'
                                                : 'Minimal shrinkage cracks'}
                                        </li>
                                    </ul>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Шлаковый цемент был признан наиболее подходящим цементом для бетонных покрытий, массового бетона, высокоэффективного или высокопрочного бетона, конструкций и фундаментов, сборного бетона, такого как трубы и блоки, бетона, подверженного воздействию морской воды и морского применения.'
                                            : lang === 'ua'
                                            ? 'Шлаковий цемент був визнаний найбільш підходящим цементом для бетонних покриттів, масового бетону, високоефективного або високоміцного бетону, конструкцій і фундаментів, збірного бетону, такого як труби і блоки, бетону, схильного до впливу морської води і морського застосування.'
                                            : 'Slag cement has been found to be the most suitable cement for concrete pavements, bulk concrete, high performance or high strength concrete, structures and foundations, precast concrete such as pipes and blocks, concrete exposed to seawater and marine applications.'}
                                    </div>
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Для более прочного бетона просто добавляйте отходы!'
                                            : lang === 'ua'
                                            ? 'Для більш міцного бетону просто додавайте відходи!'
                                            : 'For stronger concrete, just add waste!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_1_19.jpg`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Если  вы  ищите   надежного   поставщика   шлака  из Украины, обращайтесь  к  нам:  наши   менеджеры   подготовят для   вас   коммерческое   предложение,   логисты    рассчитают стоимость доставки товара по всем требованиям, наши юристы подготовят  необходимые   бумаги  для   проведения   операции международного масштаба.'
                                            : lang === 'ua'
                                            ? 'Якщо ви шукайте надійного постачальника шлака з України, звертайтеся до нас: наші менеджери підготують для вас комерційну пропозицію, логісти розрахують вартість доставки товару за всіма вимогами, наші юристи підготують необхідні папери для проведення операції міжнародного масштабу.'
                                            : 'If you are looking for a reliable slag supplier from Ukraine, contact us: our managers will prepare a commercial offer for you, logisticians will calculate the cost of delivery of goods for all requirements, our lawyers will prepare the necessary paperwork for an international operation.'}
                                    </div>
                                    <div className={classes.title} style={{ marginBottom: 0 }}>
                                        {lang === 'ru'
                                            ? 'НАШИ ПРЕИМУЩЕСТВА:'
                                            : lang === 'ua'
                                            ? 'НАШІ ПЕРЕВАГИ:'
                                            : 'OUR ADVANTAGES:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Большой опыт в логистике, который дает нам возможность предложить нашим клиентам лучшие способы доставки товаров.'
                                                : lang === 'ua'
                                                ? 'Великий досвід в логістиці, який дає нам можливість запропонувати нашим клієнтам кращі способи доставки товарів.'
                                                : 'Extensive experience in logistics, which enables us to offer our customers the best delivery methods.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Широкий спектр базисов поставки продукции (Инкотермс 2010): FCA, CPT, DAP, FOB, CFR.'
                                                : lang === 'ua'
                                                ? 'Широкий спектр базисів поставки продукції (Інкотермс 2010): FCA, CPT, DAP, FOB, CFR.'
                                                : 'A wide range of product delivery bases (Incoterms 2010): FCA, CPT, DAP, FOB, CFR.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'При каждой загрузке мы следим за тем, чтобы к нашим клиентам поступал только качественный товар. Вся отгружаемая продукция проверяется инспекционной компанией SGS на предмет качества. '
                                                : lang === 'ua'
                                                ? 'При кожному завантаженні ми стежимо за тим, щоб до наших клієнтів надходив тільки якісний товар. Вся відвантажується продукція перевіряється інспекційної компанією SGS на предмет якості.'
                                                : 'With each download, we make sure that only high-quality goods are delivered to our customers. All shipped products are checked for quality by SGS inspection company.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Мы решим все вопросы с документооборотом и быстро произведем таможенные процедуры.'
                                                : lang === 'ua'
                                                ? 'Ми вирішимо всі питання з документообігом і швидко зробимо митні процедури.'
                                                : 'We will solve all the paperwork issues and quickly carry out customs procedures.'}
                                        </li>
                                    </ul>
                                    <div className={classes.titleItalic} style={{ fontSize: 34 }}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

SlagPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
