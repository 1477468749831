export const seoDataLanguages = {
    'ru': {
        title: 'Логистический консалтинг и ВЭД',
        description: 'Логистический консалтинг и ВЭД',
        keywords:
            'компания NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехника, электроника, услуги, консалтинговые услуги, юридические услуги, финансовые услуги, пиломатериалы, логистические услуги, логистика',
    },
    'en': {
        title: 'Logistic consulting and foreign economic activity',
        description: 'Logistic consulting and foreign economic activity',
        keywords:
            'company NOLANTO, NOLANTO LTD, Nolanto, slag, plumbing, electronics, services, consulting services, legal services, financial services, lumber, logistics services, logistics',
    },
    'ua': {
        title: 'Логістичний консалтинг і ЗЕД',
        description: 'Логістичний консалтинг і ЗЕД',
        keywords:
            'компанія NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехніка, електроніка, послуги, консалтингові послуги, юридичні послуги, фінансові послуги, пиломатеріали, логістичні послуги, логістика',
    },
};
