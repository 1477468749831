export const styles = {
    root: {
        width: 'calc(100% - 60px)',
        background: '#425c5a',
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 30px',
    },
    text: {
        color: '#fff',
    },
};
