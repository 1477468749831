import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'PlumbingPage',
});

export const PlumbingPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.title} style={{ fontSize: 22, textAlign: 'center' }}>
                                        {lang === 'ru'
                                            ? 'Добро пожаловать в мир качественной сантехники!'
                                            : lang === 'ua'
                                            ? 'Ласкаво просимо в світ якісної сантехніки!'
                                            : 'Welcome to the world of quality plumbing!'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_1.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'NOLANTO может предложить вам ванные и душевые кабины, умывальники и смесители, унитазы и биде, раковины и многое другое. Для завершения идеального дизайна ванной мы также представлены аксессуары для ванной.'
                                            : lang === 'ua'
                                            ? 'NOLANTO може запропонувати вам ванні та душові кабіни, умивальники та змішувачі, унітази і біде, раковини і багато іншого. Для завершення ідеального дизайну ванної ми також представлені аксесуари для ванної.'
                                            : 'NOLANTO can offer you bathrooms and showers, washbasins and faucets, toilets and bidets, washbasins and much more. To complete the perfect bathroom design, we also present bathroom accessories.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Нашим потребителям, мы предлагаем достойный ассортимент элитной сантехнической продукции из Китая. Оригинальная и качественная сантехника, включая сопутствующие аксессуары, удовлетворит запросы каждого взыскательного клиента. NOLANTO - компания, которая смогла зарекомендовать себя как надежного партнера и обязательного поставщика продукции высокого класса.'
                                            : lang === 'ua'
                                            ? "Нашим споживачам, ми пропонуємо гідну асортимент елітної сантехнічної продукції з Китаю. Оригінальна і якісна сантехніка, включаючи супутні аксесуари, задовольнить запити кожного вимогливого клієнта. NOLANTO - компанія, яка змогла зарекомендувати себе як надійного партнера і обов'язкового постачальника продукції високого класу."
                                            : 'We offer our consumers a decent range of luxury sanitary products from China. Original and high-quality plumbing, including related accessories, will satisfy the needs of every discerning client. NOLANTO is a company that has managed to establish itself as a reliable partner and an obligatory supplier of high-class products.'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_2.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Благодаря тесным партнерским отношениям с ведущими сантехническими производителями Китая NOLANTO гарантирует постоянное обновление ассортимента и отгрузку вашего заказа в кратчайшие сроки. Кроме этого, наши клиенты получают возможность приобретать качественную продукцию по демократичным ценам. Команда NOLANTO будет рада помочь застройщикам и строительно-ремонтным организациям. Преимущества, которые предоставляются нами нашим оптовым покупателям, станут весомым аргументом в пользу долговременного сотрудничества.'
                                            : lang === 'ua'
                                            ? 'Завдяки тісним партнерським відносинам з провідними сантехнічними виробниками Китаю NOLANTO гарантує постійне оновлення асортименту і відвантаження вашого замовлення в найкоротші терміни. Крім цього, наші клієнти отримують можливість купувати якісну продукцію за демократичними цінами. Команда NOLANTO буде рада допомогти забудовникам і будівельно-ремонтних організацій. Переваги, які надаються нами нашим оптовим покупцям, стануть вагомим аргументом на користь довготривалої співпраці.'
                                            : 'Thanks to close partnerships with leading plumbing manufacturers in China, NOLANTO guarantees a constant update of the range and the shipment of your order as soon as possible. In addition, our clients get the opportunity to purchase quality products at affordable prices. The NOLANTO team will be happy to help developers and construction and repair organizations. The advantages that we provide to our wholesale customers will become a strong argument in favor of long-term cooperation.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.title}>
                                    {lang === 'ru'
                                        ? 'Унитазы и биде'
                                        : lang === 'ua'
                                        ? 'Унітази та біде'
                                        : 'Toilet bowls and bidets'}
                                </div>
                                <div className={classes.left}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_3.png`}
                                        alt="image"
                                        className={classes.img}
                                    />
                                    <br />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_4.png`}
                                        alt="image"
                                        className={classes.img}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexFlow: 'column wrap',
                                        justifyContent: 'space-between',
                                        minHeight: 443,
                                    }}
                                >
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Представленные унитазы и биде отлично впишутся в любой интерьер и стиль санузла. Каждая модель оснащена максимальным удобством и повышенной комфортабельностью. Такие изделия подчеркнут великолепный вкус хозяина и его высокий статус. Унитазы и биде могут иметь разный материал изготовлений и разную форму.'
                                            : lang === 'ua'
                                            ? "Представлені унітази і біде відмінно впишуться в будь-який інтер'єр і стиль санвузла. Кожна модель оснащена максимальною зручністю та підвищеною комфортабельністю. Такі вироби підкреслять чудовий смак господаря і його високий статус. Унітази та біде можуть мати різний матеріал приготувань і різну форму."
                                            : 'The presented toilets and bidets will perfectly fit into any interior and bathroom style. Each model is equipped with maximum convenience and increased comfort. Such products will emphasize the great taste of the owner and his high status. Toilets and bidets can be made of different materials and shapes.'}
                                    </div>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_5.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                            style={{ marginLeft: 70, maxWidth: 300, maxHeight: 300 }}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_6.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                            style={{ maxWidth: 350, maxHeight: 350 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.title}>
                                    {lang === 'ru'
                                        ? 'Умывальники и тумбы'
                                        : lang === 'ua'
                                        ? 'Умивальники та тумби'
                                        : 'Washbasins and vanities'}
                                </div>
                                <div className={classes.right}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_7.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <br />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_8.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexFlow: 'column wrap',
                                        justifyContent: 'space-between',
                                        minHeight: 502,
                                    }}
                                >
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Вы никогда не будете волноваться за воду, выбирая продукцию с безграничными возможностями от лучших производителей.'
                                            : lang === 'ua'
                                            ? 'Ви ніколи не будете хвилюватися за воду, вибираючи продукцію з безмежними можливостями від кращих виробників.'
                                            : 'You will never worry about water by choosing products with limitless possibilities from the best manufacturers.'}
                                    </div>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_9.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                            style={{ maxWidth: 350, maxHeight: 350, marginRight: 70 }}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_10.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                            style={{ maxWidth: 300, maxHeight: 300 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.title}>
                                    {lang === 'ru' ? 'Смесители' : lang === 'ua' ? 'Змішувачі' : 'Faucets'}
                                </div>
                                <div>
                                    <div className={classes.right}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_11.png`}
                                            alt="image"
                                            className={classNames(classes.img)}
                                        />
                                        <br />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_15.png`}
                                            alt="image"
                                            className={classNames(classes.img)}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_12.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                            style={{ width: 'auto', maxHeight: 431 }}
                                        />
                                        <div className={classes.text}>
                                            {lang === 'ru'
                                                ? 'На сегодняшний день смеситель играет большую роль в дизайне интерьера ванной комнаты. Дизайнеры обращают внимание на внешний вид и функциональность смесителя как ключевого элемента интерьера. Оригинальные модели с собственным характером — это одно из главных условий для создания обновленных и современных пространств как в результате новой постройки, так и ремонтных работ.'
                                                : lang === 'ua'
                                                ? "На сьогоднішній день змішувач грає велику роль в дизайні інтер'єру ванної кімнати. Дизайнери звертають увагу на зовнішній вигляд і функціональність змішувача як ключового елементу інтер'єру. Оригінальні моделі з власним характером - це одна з головних умов для створення оновлених і сучасних просторів як в результаті нової споруди, так і ремонтних робіт."
                                                : 'Today, the faucet plays an important role in bathroom interior design. Designers pay attention to the appearance and functionality of the faucets as a key element of the interior. Original models with their own character are one of the main conditions for creating updated and modern spaces, both as a result of new construction and renovation work.'}
                                        </div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_13.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.right)}
                                        />
                                        <div className={classes.text}>
                                            {lang === 'ru'
                                                ? 'Следуя актуальной тенденции эксклюзивности интерьеров, NOLANTO представляет широкий выбор оригинальных смесителей с собственным характером.'
                                                : lang === 'ua'
                                                ? "Дотримуючись актуальною тенденції ексклюзивності інтер'єрів, NOLANTO представляє широкий вибір оригінальних змішувачів з власним характером."
                                                : 'Following the current trend of exclusivity in interiors, NOLANTO presents a wide selection of original faucets with their own character.'}
                                        </div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_14.png`}
                                            alt="image"
                                            className={classNames(classes.img, classes.left)}
                                        />
                                        <div className={classes.text}>
                                            {lang === 'ru'
                                                ? 'Помимо стандартного хромированного покрытия, мы предлагаем нашим клиентам большой ассортимент различных типов отделки смесителей как для раковины, душа или ванны, так и для кухни.'
                                                : lang === 'ua'
                                                ? 'Крім стандартного хромованого покриття, ми пропонуємо нашим клієнтам великий асортимент різних типів обробки змішувачів як для раковини, душа або ванни, так і для кухні.'
                                                : 'In addition to the standard chrome finish, we offer our customers a wide range of different types of faucet finishes, both for sinks, showers or bathtubs and for kitchens.'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_16.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Ванные, душевые кабины и гидромассажные системы'
                                            : lang === 'ua'
                                            ? 'Ванні, душеві кабіни і гідромасажні системи'
                                            : 'Bathrooms, showers and hydromassage systems'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_17.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Роскошь в сочетании со стилем дает возможность получить уникальный опыт гидромассажа. Удивительное сочетание эстетики, надежности и эргономичности, полностью соответствующих вашему бюджету.'
                                            : lang === 'ua'
                                            ? 'Розкіш в поєднанні зі стилем дає можливість отримати унікальний досвід гідромасажу. Дивовижне поєднання естетики, надійності і ергономічності, які повністю відповідають вашому бюджету.'
                                            : 'Luxury meets style for a unique hydromassage experience. An amazing combination of aesthetics, reliability and ergonomics to suit your budget.'}
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                            flexFlow: 'row wrap',
                                        }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_18.png`}
                                            alt="image"
                                            className={classes.img}
                                            style={{ width: 'auto', maxHeight: 236 }}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_19.png`}
                                            alt="image"
                                            className={classes.img}
                                            style={{ maxHeight: 236 }}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_20.png`}
                                            alt="image"
                                            className={classes.img}
                                            style={{ maxHeight: 236 }}
                                        />
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/product_img_4_21.png`}
                                            alt="image"
                                            className={classes.img}
                                            style={{ maxHeight: 236 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_22.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Мебель и аксессуары для ванной'
                                            : lang === 'ua'
                                            ? 'Меблі та аксесуари для ванної'
                                            : 'Bathroom furniture and accessories'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Мебель и аксессуары, поставляемые NOLANTO, обладают практичностью и удобством, которое очень высоко ценится современными потребителями.'
                                            : lang === 'ua'
                                            ? 'Меблі та аксесуари, що поставляються NOLANTO, мають практичністю і зручністю, яке дуже високо цінується сучасними споживачами.'
                                            : 'Furniture and accessories supplied by NOLANTO offer practicality and convenience that are highly appreciated by modern consumers.'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_23.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                        style={{ width: 'auto' }}
                                    />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_4_24.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                        style={{ float: 'revert' }}
                                    />
                                    <div
                                        className={classes.titleItalic}
                                        style={{
                                            fontWeight: 400,
                                            textDecoration: 'underline',
                                            fontStyle: 'italic',
                                            fontSize: 22,
                                        }}
                                    >
                                        {lang === 'ru'
                                            ? 'Мы предлагаем своим покупателям только ту продукцию,'
                                            : lang === 'ua'
                                            ? 'Ми пропонуємо своїм покупцям тільки ту продукцію,'
                                            : 'We offer our customers only those products'}
                                        <br />
                                        {lang === 'ru'
                                            ? 'в которой мы сами уверенны на все 100%'
                                            : lang === 'ua'
                                            ? 'в якій ми самі впевнені на всі 100%'
                                            : 'in which we ourselves are 100% confident'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'Мы всегда заботимся о своих клиентах, как настоящих, так и будущих, поэтому всегда идем навстречу покупателям и предоставляем выгодные условия сотрудничества.'
                                            : lang === 'ua'
                                            ? 'We always care about our customers, both present and future, so we always meet customers halfway and provide favorable terms of cooperation.'
                                            : 'Ми завжди дбаємо про своїх клієнтів, як справжніх, так і майбутніх, тому завжди йдемо назустріч покупцям і надаємо вигідні умови співпраці.'}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_1_19.jpg`}
                                        alt="image"
                                        className={classNames(classes.img, classes.right)}
                                    />
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Если Вы   ищите   надежного   поставщика    оригинальной и качественной сантехники, включая сопутствующие аксессуары, обращайтесь   к   нам: наши   менеджеры   подготовят   для    вас   коммерческое   предложение, логисты   рассчитают    стоимость доставки товара по всем требованиям, наши юристы подготовят необходимые бумаги для проведения операции международного масштаба.'
                                            : lang === 'ua'
                                            ? 'Якщо Ви шукайте надійного постачальника оригінальної і якісної сантехніки, включаючи супутні аксесуари, звертайтеся до нас: наші менеджери підготують для вас комерційну пропозицію, логісти розрахують вартість доставки товару за всіма вимогами, наші юристи підготують необхідні папери для проведення операції міжнародного масштабу.'
                                            : 'If you are looking for a reliable supplier of original and high-quality sanitary ware, including related accessories, please contact us: our managers will prepare a commercial offer for you, logisticians will calculate the cost of delivery of goods for all requirements, our lawyers will prepare the necessary papers for an international operation.'}
                                    </div>
                                    <div className={classes.title} style={{ margin: '20px 0 0' }}>
                                        {lang === 'ru'
                                            ? 'НАШИ ПРЕИМУЩЕСТВА:'
                                            : lang === 'ua'
                                            ? 'НАШІ ПЕРЕВАГИ:'
                                            : 'OUR ADVANTAGES:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Правильная и четко организованная структура управления.'
                                                : lang === 'ua'
                                                ? 'Правильна і чітко організована структура управління.'
                                                : 'Correct and well-organized management structure.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Решение любой задачи быстро и эффективно.'
                                                : lang === 'ua'
                                                ? 'Рішення будь-якого завдання швидко і ефективно.'
                                                : 'Solving any task quickly and efficiently.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Гарантийный сертификат для каждого изделия. '
                                                : lang === 'ua'
                                                ? 'Гарантійний сертифікат для кожного виробу.'
                                                : 'Warranty certificate for each product.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Большой опыт в логистике.'
                                                : lang === 'ua'
                                                ? 'Великий досвід в логістиці.'
                                                : 'Extensive experience in logistics.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Контроль всех этапов исполнения.'
                                                : lang === 'ua'
                                                ? 'Контроль всіх етапів виконання.'
                                                : 'Control of all stages of execution.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Покупая у нас, Вы не только экономите свои деньги, но и время.'
                                                : lang === 'ua'
                                                ? 'Купуючи у нас, Ви не тільки економите свої гроші, а й час.'
                                                : 'Buying from us, you not only save your money, but also time.'}
                                        </li>
                                    </ul>
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

PlumbingPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
