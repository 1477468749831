export const styles = {
    root: {
        width: '85%',
        margin: '0 auto',
        padding: '30px 0',
    },
    title: {
        color: '#314644',
        textAlign: 'center',
        margin: '0 0 20px 0',
        fontSize: '32px',
        fontWeight: 700,
    },
    text: {
        fontSize: 16,
    },
    link: {
        outline: 'none',
        display: 'block',
        color: '#425c5a',
        fontWeight: 700,
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',

        '@media (max-width: 992px)': {
            display: 'block',
        },
    },
    contacts: {
        width: 'calc(50% - 100px)',
        padding: '0 50px',

        '@media (max-width: 992px)': {
            width: '100%',
            padding: 0,
        },
    },
    contact: {
        display: 'flex',
        margin: 20,
        alignItems: 'center',
    },
    icon: {
        width: 24,
        margin: '5px 30px 0 0',
    },
    form: {
        width: '50%',

        '@media (max-width: 992px)': {
            width: '100%',
            textAlign: 'center',
        },
    },
};
