export const CONTACTS_LANGUAGES = {
    ru: {
        title: 'Контакты',
        lastName: 'Имя',
        company: 'Компания',
        town: 'Город',
        phone: 'Телефон',
        request: 'Запрос',
        agree: 'Политика конфиденциальности',
        lastNameError: 'Имя является обязательным полем',
        companyError: 'Компания является обязательным полем',
        phoneError: 'Телефон является обязательным полем',
        emailValidateError: 'Email должен содержать @ и .!',
        emailError: 'Email является обязательным полем',
        requestError: 'Запрос является обязательным полем',
        agreeError: 'Соглашение является обязательным полем',
        button: 'Отправить',
        successMessage: 'Ваш запрос успешно отправлен.',
        errorMessage: 'Ваш запрос не был отправлен. Попробуйте позже.',
    },
    en: {
        title: 'Contacts',
        lastName: 'Forename(s) Last name',
        company: 'Company',
        town: 'Town/City',
        phone: 'Telephone',
        request: 'Request',
        agree: 'Private Policy',
        lastNameError: 'Name is required field!',
        companyError: 'Company is required field!',
        phoneError: 'Telephone is required field!',
        emailValidateError: 'Email must have @ and .!',
        emailError: 'Email is required field!',
        requestError: 'Request is required field!',
        agreeError: 'Agreement is required field!',
        button: 'Send',
        successMessage: 'Your request was successfully send.',
        errorMessage: 'Your request was failed. Try again later.',
    },
    ua: {
        title: 'Контакти',
        lastName: 'Им`я',
        company: 'Компанiя',
        town: 'Мiсто',
        phone: 'Телефон',
        request: 'Запит',
        agree: 'Політика конфіденційності',
        lastNameError: 'Им`я є обов`язковим полем! ',
        companyError: 'Компанiя є обов`язковим полем! ',
        phoneError: 'Телефон є обов`язковим полем! ',
        emailValidateError: 'Email має мiстити @ та .! ',
        emailError: 'Email є обов`язковим полем! ',
        requestError: 'Запит є обов`язковим полем! ',
        agreeError: 'Угода є обов`язковим полем! ',
        button: 'Вiдправити',
        successMessage: 'Ваш запит був успiшно вiдправлен.',
        errorMessage: 'Ваш запит не був вiдправлен. Спробуйте пiзнiше.',
    },
};
