import React, { Fragment, useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { PRODUCTS_LANGUAGES } from '../../constants/productLabguages';
import { SeoTags } from '../../components/SeoTags';
import classNames from 'classnames';
import { createElementsFromText } from 'html-text-to-react';
import { createUseStyles } from 'react-jss';
import propTypes from 'prop-types';
import { seoDataLanguages } from './seoData';
import { styles } from './styles';
import { withLang } from 'react-multi-language';

const useStyles = createUseStyles(styles, {
    name: 'FinancialConsultingPage',
});

export const FinancialConsultingPage = withLang(PRODUCTS_LANGUAGES)(({ lang, langProps }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    setTimeout(() => setIsLoading(false), 400);

    return (
        <div className={classes.root}>
            <SeoTags seo={seoDataLanguages[lang] || {}} />
            {isLoading && <Loader />}
            {!isLoading && (
                <Fragment>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={false}
                        className={classes.subProducts}
                        infiniteLoop={true}
                    >
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.text}>
                                        {createElementsFromText(
                                            lang === 'ru'
                                                ? 'NOLANTO предлагает Вам квалифицированную помощь для старта Вашей деятельности в Украине.'
                                                : lang === 'ua'
                                                ? 'NOLANTO пропонує Вам кваліфіковану допомогу для старту Вашої діяльності в Україні.'
                                                : 'NOLANTO offers you qualified assistance to start your business in Ukraine.',
                                        )}
                                    </div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/product_img_7_1.png`}
                                        alt="image"
                                        className={classNames(classes.img, classes.left)}
                                    />
                                    <div className={classes.text} style={{ margin: 0 }}>
                                        {lang === 'ru'
                                            ? 'Принимая во внимание объемы и особенности Вашего бизнеса, учитывая Ваши цели и задачи, мы  поможем иностранным инвесторам определиться с организационно-правовой формой для украинской компании, подобрать наиболее подходящую систему налогообложения, рассчитать затраты, необходимые для начала деятельности. В случае необходимости, наши партнеры могут провести комплексный анализ рынка в том сегменте, который Вас интересует и предоставить услуги по подбору квалифицированного персонала.'
                                            : lang === 'ua'
                                            ? 'Беручи до уваги обсяги та особливості Вашого бізнесу, враховуючи Ваші цілі і завдання, ми допоможемо іноземним інвесторам визначитися з організаційно-правовою формою для української компанії, підібрати найбільш підходящу систему оподаткування, розрахувати витрати, необхідні для початку діяльності. У разі необхідності, наші партнери можуть провести комплексний аналіз ринку в тому сегменті, який Вас цікавить і надати послуги з підбору кваліфікованого персоналу.'
                                            : 'Taking into account the volumes and peculiarities of your business, taking into account your goals and objectives, we will help foreign investors to determine the organizational and legal form for a Ukrainian company, choose the most suitable taxation system, and calculate the costs necessary to start activities. If necessary, our partners can conduct a comprehensive analysis of the market in the segment that interests you and provide services for the selection of qualified personnel.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru'
                                            ? 'Основная задача нашей компании – комплексная поддержка иностранных инвесторов, которые собираются или уже ведут деятельность на рынке Украины, а именно: предоставление широкого спектра юридических, бухгалтерских, кадровых услуг, начиная от вводной консультации и решения организационных вопросов для старта Вашего бизнеса и заканчивая комплексным сопровождением хозяйственной деятельности.'
                                            : lang === 'ua'
                                            ? 'Основне завдання нашої компанії - комплексна підтримка іноземних інвесторів, які збираються або вже ведуть діяльність на ринку України, а саме: надання широкого спектру юридичних, бухгалтерських, кадрових послуг, починаючи від вступної консультації та вирішення організаційних питань для старту Вашого бізнесу і закінчуючи комплексним супроводом господарської діяльності.'
                                            : 'The main task of our company is comprehensive support of foreign investors who are going to or are already operating in the Ukrainian market, namely: providing a wide range of legal, accounting, personnel services, ranging from introductory consultation and solving organizational issues for starting your business and ending with comprehensive support of economic activities.'}
                                    </div>
                                    <div className={classes.text}>
                                        {lang === 'ru' ? 'Мы ' : lang === 'ua' ? 'Ми ' : 'We '}
                                        <b>
                                            {lang === 'ru'
                                                ? 'работаем только с проверенными партнерами и агентами по всему миру, '
                                                : lang === 'ua'
                                                ? 'працюємо тільки з перевіреними партнерами і агентами по всьому світу, '
                                                : 'work only with trusted partners and agents around the world, '}
                                        </b>
                                        {lang === 'ru'
                                            ? 'которые помогают нам быть в курсе всех законодательных и налоговых изменений, решать сложные задачи бизнеса.'
                                            : lang === 'ua'
                                            ? 'які допомагають нам бути в курсі всіх законодавчих і податкових змін, вирішувати складні завдання бізнесу.'
                                            : 'who help us to keep abreast of all legislative and tax changes, to solve complex business problems.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div>
                                <Link className={classes.button} to="/contacts">
                                    {langProps.buttonToContactUs}
                                </Link>
                                <div style={{ 'clear': 'both' }} />
                                <div className={classes.inner}>
                                    <div className={classes.title}>
                                        {lang === 'ru'
                                            ? 'ОСНОВНЫЕ НАПРАВЛЕНИЯ ДЕЯТЕЛЬНОСТИ КОМПАНИИ:'
                                            : lang === 'ua'
                                            ? 'ОСНОВНІ НАПРЯМКИ ДІЯЛЬНОСТІ КОМПАНІЇ:'
                                            : 'MAIN DIRECTIONS OF THE COMPANY:'}
                                    </div>
                                    <ul className={classes.list}>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Аудиторские услуги по украинскому законодательству.'
                                                : lang === 'ua'
                                                ? 'Аудиторські послуги за українським законодавством.'
                                                : 'Audit services under Ukrainian legislation.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Консалтинговые услуги в сфере экономики и финансов.'
                                                : lang === 'ua'
                                                ? 'Консалтингові послуги в сфері економіки і фінансів.'
                                                : 'Consulting services in the field of economics and finance.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Консультационные услуги по налоговому законодательству Украины.'
                                                : lang === 'ua'
                                                ? 'Консультаційні послуги з податкового законодавства України.'
                                                : 'Consulting services on tax legislation of Ukraine.'}
                                        </li>
                                        <li className={classes.item}>
                                            {lang === 'ru'
                                                ? 'Обучающий консалтинг.'
                                                : lang === 'ua'
                                                ? 'Навчальний консалтинг.'
                                                : 'Educational consulting.'}
                                        </li>
                                        <li className={classes.item}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/product_img_7_2.png`}
                                                alt="image"
                                                className={classNames(classes.img, classes.right)}
                                                style={{ minWidth: 350 }}
                                            />
                                            {lang === 'ru'
                                                ? 'Экспертный консалтинг.'
                                                : lang === 'ua'
                                                ? 'Експертна консалтинг.'
                                                : 'Expert consulting.'}
                                        </li>
                                    </ul>
                                    <div className={classes.text}>
                                        {createElementsFromText(
                                            lang === 'ru'
                                                ? 'NOLANTO оказывает услуги по сопровождению бизнеса, а также бизнес-проектов как украинских, так и иностранных компаний. Мы успешно предоставляем услуги во всех аспектах деятельности предприятий.'
                                                : lang === 'ua'
                                                ? 'NOLANTO надає послуги з супроводу бізнесу, а також бізнес-проектів як українських, так і іноземних компаній. Ми успішно надаємо послуги в усіх аспектах діяльності підприємств.'
                                                : 'NOLANTO provides business support services, as well as business projects of both Ukrainian and foreign companies. We successfully provide services in all aspects of business activities.',
                                        )}
                                        <ul className={classes.list}>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'подготовка заключений по вопросам соответствия бизнес-проекта действующему законодательству Украины;'
                                                    : lang === 'ua'
                                                    ? 'підготовка висновків з питань відповідності бізнес-проекту чинному законодавству України;'
                                                    : 'preparation of opinions on the compliance of a business project with the current legislation of Ukraine;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'сопровождение сделок, работа с инвесторами;'
                                                    : lang === 'ua'
                                                    ? 'супровід угод, робота з інвесторами;'
                                                    : 'support of transactions, work with investors;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'стратегия и инновационные решения для бизнеса;'
                                                    : lang === 'ua'
                                                    ? 'стратегія і інноваційні рішення для бізнесу;'
                                                    : 'strategy and innovative solutions for business;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'подготовка документов для получения разрешительной документации;'
                                                    : lang === 'ua'
                                                    ? 'підготовка документів для отримання дозвільної документації;'
                                                    : 'preparation of documents for obtaining permits;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'подготовка проектов договоров и юридическое сопровождение сделок;'
                                                    : lang === 'ua'
                                                    ? 'підготовка проектів договорів та юридичний супровід угод;'
                                                    : 'preparation of draft agreements and legal support of transactions;'}
                                            </li>
                                            <li className={classes.item}>
                                                {lang === 'ru'
                                                    ? 'представление интересов в государственных органах, получение разрешительных и правоустанавливающих документов;'
                                                    : lang === 'ua'
                                                    ? 'представлення інтересів в державних органах, отримання дозвільних та правовстановлюючих документів;'
                                                    : 'representation of interests in state bodies, obtaining permits and documents of title;'}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={classes.title} style={{ fontWeight: 400, textAlign: 'center' }}>
                                        <b>
                                            {lang === 'ru'
                                                ? 'Гарантируем конфиденциальность информации, '
                                                : lang === 'ua'
                                                ? 'Гарантуємо конфіденційність інформації, '
                                                : 'We guarantee the confidentiality of information '}
                                        </b>
                                        {lang === 'ru'
                                            ? 'которую доверяет нам клиент.'
                                            : lang === 'ua'
                                            ? 'яку довіряє нам клієнт.'
                                            : 'that the client entrusts to us.'}
                                    </div>
                                    <div className={classes.titleItalic}>
                                        {lang === 'ru'
                                            ? 'Мы готовы стать вашим надежным поставщиком!'
                                            : lang === 'ua'
                                            ? 'Ми готові стати вашим надійним постачальником!'
                                            : 'We are ready to become your reliable supplier!'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </Fragment>
            )}
        </div>
    );
});

FinancialConsultingPage.propTypes = {
    lang: propTypes.string,
    langProps: propTypes.object,
};
