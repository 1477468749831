export const HEADER_LANGUAGES = {
    ru: {
        partnersTitle: 'Наши партнеры',
        titles: [
            {
                title: 'Пиломатериалы',
                isProduct: true,
                img: 'product_img_1.jpg',
                subTitles: [
                    {
                        title: 'Пиломатериалы',
                        route: 'lumber',
                        isProduct: true,
                        img: 'product_img_1_3.jpg',
                    },
                ],
            },
            {
                title: 'Товары народного потребления',
                isProduct: true,
                img: 'product_img_2.jpg',
                subTitles: [
                    {
                        title: 'Электроника',
                        route: 'electronics',
                        isProduct: true,
                        img: 'product_img_8_1.png',
                    },
                    {
                        title: 'Сантехника',
                        route: 'plumbing',
                        isProduct: true,
                        img: 'product_img_4_1.png',
                    },
                ],
            },
            {
                title: 'Строительство',
                isProduct: true,
                img: 'product_img_3.jpg',
                subTitles: [
                    {
                        title: 'Шлак',
                        route: 'slag',
                        isProduct: true,
                        img: 'product_img_3_1.jpg',
                    },
                ],
            },
            {
                title: 'Услуги',
                isProduct: true,
                img: 'product_img_4.jpg',
                subTitles: [
                    {
                        title: 'Логистический консалтинг и ВЭД',
                        route: 'logistic-consulting',
                        isProduct: true,
                        img: 'product_img_5_1.png',
                    },
                    {
                        title: 'Юридический консалтинг',
                        route: 'law-consulting',
                        isProduct: true,
                        img: 'product_img_6_1.png',
                    },
                    {
                        title: 'Финансовый консалтинг',
                        route: 'financial-consulting',
                        isProduct: true,
                        img: 'product_img_7_1.png',
                    },
                ],
            },
            {
                title: 'Контакты',
                route: 'contacts',
                isProduct: false,
            },
        ],
    },
    en: {
        partnersTitle: 'Our partners',
        titles: [
            {
                title: 'Lumber',
                isProduct: true,
                img: 'product_img_1.jpg',
                subTitles: [
                    {
                        title: 'Lumber',
                        route: 'lumber',
                        isProduct: true,
                        img: 'product_img_1_3.jpg',
                    },
                ],
            },
            {
                title: 'Common consumption goods',
                route: 'common',
                isProduct: true,
                img: 'product_img_2.jpg',
                subTitles: [
                    {
                        title: 'Electronics',
                        route: 'electronics',
                        isProduct: true,
                        img: 'product_img_8_1.png',
                    },
                    {
                        title: 'Plumbing',
                        route: 'plumbing',
                        isProduct: true,
                        img: 'product_img_4_1.png',
                    },
                ],
            },
            {
                title: 'Construction',
                isProduct: true,
                img: 'product_img_3.jpg',
                subTitles: [
                    {
                        title: 'Slag',
                        route: 'slag',
                        isProduct: true,
                        img: 'product_img_3_1.jpg',
                    },
                ],
            },
            {
                title: 'Services',
                isProduct: true,
                img: 'product_img_4.jpg',
                subTitles: [
                    {
                        title: 'Logistic consulting and FEA',
                        route: 'logistic-consulting',
                        isProduct: true,
                        img: 'product_img_5_1.png',
                    },
                    {
                        title: 'Legal consulting',
                        route: 'law-consulting',
                        isProduct: true,
                        img: 'product_img_6_1.png',
                    },
                    {
                        title: 'Financial advisory',
                        route: 'financial-consulting',
                        isProduct: true,
                        img: 'product_img_7_1.png',
                    },
                ],
            },
            {
                title: 'Contacts',
                route: 'contacts',
                isProduct: false,
            },
        ],
    },
    ua: {
        partnersTitle: 'Наши партнери',
        titles: [
            {
                title: 'Пиломатеріали',
                isProduct: true,
                img: 'product_img_1.jpg',
                subTitles: [
                    {
                        title: 'Пиломатеріали',
                        route: 'lumber',
                        isProduct: true,
                        img: 'product_img_1_3.jpg',
                    },
                ],
            },
            {
                title: 'Товари народного споживання',
                route: 'common',
                isProduct: true,
                img: 'product_img_2.jpg',
                subTitles: [
                    {
                        title: 'Електронiка',
                        route: 'electronics',
                        isProduct: true,
                        img: 'product_img_8_1.png',
                    },
                    {
                        title: 'Сантехнiка',
                        route: 'plumbing',
                        isProduct: true,
                        img: 'product_img_4_1.png',
                    },
                ],
            },
            {
                title: 'Будівництво',
                isProduct: true,
                img: 'product_img_3.jpg',
                subTitles: [
                    {
                        title: 'Шлак',
                        route: 'slag',
                        isProduct: true,
                        img: 'product_img_3_1.jpg',
                    },
                ],
            },
            {
                title: 'Послуги',
                isProduct: true,
                img: 'product_img_4.jpg',
                subTitles: [
                    {
                        title: 'Логістичний консалтинг і ЗЕД',
                        route: 'logistic-consulting',
                        isProduct: true,
                        img: 'product_img_5_1.png',
                    },
                    {
                        title: 'Юридичний консалтинг',
                        route: 'law-consulting',
                        isProduct: true,
                        img: 'product_img_6_1.png',
                    },
                    {
                        title: 'Фінансовий консалтинг',
                        route: 'financial-consulting',
                        isProduct: true,
                        img: 'product_img_7_1.png',
                    },
                ],
            },
            {
                title: 'Контакти',
                route: 'contacts',
                isProduct: false,
            },
        ],
    },
};
