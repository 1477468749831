export const seoDataLanguages = {
    'ru': {
        title: 'Главная',
        description: 'Главная страница компании NOLANTO LTD',
        keywords:
            'компания NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехника, электроника, услуги, консалтинговые услуги, юридические услуги, финансовые услуги, пиломатериалы, логистические услуги, логистика',
    },
    'en': {
        title: ' Home',
        description: 'Home page of company NOLANTO LTD',
        keywords:
            'company NOLANTO, NOLANTO LTD, Nolanto, slag, plumbing, electronics, services, consulting services, legal services, financial services, lumber, logistics services, logistics',
    },
    'ua': {
        title: 'Головна',
        description: 'Головна сторінка компанії NOLANTO LTD',
        keywords:
            'компанія NOLANTO, NOLANTO LTD, Nolanto, шлак, сантехніка, електроніка, послуги, консалтингові послуги, юридичні послуги, фінансові послуги, пиломатеріали, логістичні послуги, логістика',
    },
};
